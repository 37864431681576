import gql from 'graphql-tag';

const FORGOT_PASSWORD = gql`
  mutation forgetPassword($phone: String!) {
    forgetPassword(phone: $phone) {
      status
      message
      message_ar
      responseCode
    }
  }
`;

export default FORGOT_PASSWORD;
