import React from 'react';
import styled from '@emotion/styled/macro';

import { useTranslation } from 'react-i18next';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: 1/2;
  grid-row: 2/3;
  @media screen and (max-width: 820px) {
    order: 3;
    align-self: auto;
  }

  p {
    font-size: 14px;
    a {
      margin-right: 5px;
      text-decoration: none;
    }
  }
`;
const Rights = styled.div``;

const InfoFooter = () => {
  const { t } = useTranslation('footer');

  return (
    <InfoWrapper>
      <Rights>
        <p>{t('info.rights')}</p>
      </Rights>
    </InfoWrapper>
  );
};

export default InfoFooter;
