import gql from 'graphql-tag';

export default gql`
  mutation contactUs(
    $name: String!
    $phone: String!
    $reason: String!
    $details: String
  ) {
    contactUs(name: $name, phone: $phone, reason: $reason, details: $details) {
      status
      message
      message_ar
    }
  }
`;
