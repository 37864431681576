import gql from 'graphql-tag';

export default gql`
    query searchTrips(
        $from: ID!
        $to: ID!
        $trip_date: String!
    ) {
        searchTrips(
            from_location_id: $from
            to_location_id: $to
            travel_date: $trip_date
        ) {
            trips {
                id
                ref_code
                access_level
                date
                fromTime
                toTime
                status
                is_active
                available_seats_count
                busSalon {
                    id
                    name
                    layout {
                        key
                        rows
                        cols
                        type
                        seat_type_id
                        numbering
                    }
                    seat_types {
                        id
                        name_en
                        name_ar
                        rows
                        cols
                    }
                }
                locations {
                    id
                    name_en
                    name_ar

                    city{
                        id
                        name_en
                        name_ar
                    }

                    pivot{
                        date
                        time
                    }

                }
                routeLine {
                    id
                    from_city {
                        id
                        code
                        name_en
                        name_ar
                    }
                    to_city {
                        id
                        code
                        name_en
                        name_ar
                    }

                    prices {
                        id
                        seat_type {
                            id
                            name_en
                            name_ar
                            rows
                            cols
                        }
                        promo_code {
                            id
                            active
                            name
                        }
                        price
                        discount_price
                    }
                }
            }
            isSuggestion
            allFullyBooked
            areSameCityTrips
        }
    }
`;
