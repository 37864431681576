import React from 'react';
import styled from '@emotion/styled/macro';

const Link = styled.a`
  text-decoration: none;
  color: #009fe3;
`;

const PlainLink = ({ children, href }) => {
  return <Link href={href}>{children}</Link>;
};

export default PlainLink;
