/* The app has a guest access. A guest can navigate and book tickets. However, on checkout
he's redirected to register, login, verification form based on his interactions.*/

import React, {  useState } from 'react';
import LoginForm from 'scenes/auth/login/login-form';
import RegisterForm from 'scenes/auth/register/register-form';
import VerificationForm from 'scenes/auth/verify/verification-form';

const GuestCheckout = () => {
  const [step, setStep] = useState('register');
  const [customerData, setCustomerData] = useState(null);
  const [guest, setGuest] = useState(false);

  switch (step) {
    case 'register':
      return (
        <RegisterForm setCustomerData={setCustomerData} setStep={setStep} guest={guest} setGuest={setGuest}/>
      );
    case 'login':
      return <LoginForm setCustomerData={setCustomerData} setStep={setStep} />;
    case 'verification':
      return <VerificationForm customerData={customerData} setStep={setStep} guest={guest} />;
    default:
      return (
        <RegisterForm setCustomerData={setCustomerData} setStep={setStep}  />
      );
  }
};

export default GuestCheckout;
