import gql from 'graphql-tag';

const GUEST_REGISTER = gql`
  mutation geustRegistration(
    $name: String!
    $phone: String!
    $email: String
  ) {
    guestRegistration(
      name: $name
      phone: $phone
      email: $email
    ) {
        status
      responseCode
      message
      message_ar
      token
      code
      data {
        id
        name
        registered
        is_valid
        phone
        email
      }
    }
  }
`;

export default GUEST_REGISTER;
