import gql from 'graphql-tag';

export const GET_CANCEL_DEDUCTION_AMOUNT = gql`
  query getCustomerEditTicketDeductedAmount($id: ID!) {
    getCustomerEditTicketDeductedAmount(id: $id) {
      status
      message
      message_ar
      data {
        ticket {
          id
          price
          trip {
            id
            ref_code
            access_level
            date
            status
            is_active
            busSalon {
              id
              name
              layout {
                key
                rows
                cols
                type
                seat_type_id
                numbering
              }
              seat_types {
                id
                name_en
                name_ar
                rows
                cols
              }
            }
            locations {
              id
              name_en
              name_ar
              pivot {
                time
              }
            }
          }
        }
        deduction_amount
      }
    }
  }
`;

export default GET_CANCEL_DEDUCTION_AMOUNT;
