/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const DoorIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      sroke={color}
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        width: 15px;
        height: 15px;
        vertical-align: middle;
      `}
    >
      <path
        fill={color}
        d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z"
      />
    </svg>
  );
};

export default DoorIcon;

DoorIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
