import gql from 'graphql-tag';

const GET_CUSTOMER_NOTIFICATIONS = gql`
  query getCustomerNotifications {
    getCustomerNotifications(first: 8, page: 1) {
      data {
        id
        title_en
        title_ar
        message_en
        message_ar
        created_at
          pivot{
              is_read
          }
      }
    }
  }
`;

export default GET_CUSTOMER_NOTIFICATIONS;
