import gql from 'graphql-tag';

export default gql`
  mutation createCart {
    createCart {
      id
      token
      created_at
      updated_at
    }
  }
`;
