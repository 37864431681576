import gql from 'graphql-tag';

export default gql`
  mutation tripReservedSeats(
    $fromLocationId: ID!
    $toLocationId: ID!
    $tripId: ID!
  ) {
    tripReservedSeats(
      from_location_id: $fromLocationId
      to_location_id: $toLocationId
      trip_id: $tripId
    ) {
      reserved_seats {
        seat_number
      }
    }
  }
`;
