import gql from 'graphql-tag';

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($customerId: ID!, $name: String!, $email: String!) {
    updateCustomerFront(customer_id: $customerId, name: $name, email: $email) {
      status
      message
      message_ar
      responseCode
      data {
        id
      }
    }
  }
`;

export default UPDATE_CUSTOMER;
