import gql from 'graphql-tag';
import { ticketFragment } from './ticket_fragment';

export default gql`
    mutation addCustomerCartTickets($cartToken: String!,$trip_id:ID!,$trip_route_line_id:ID!,$from_location_id:ID!,$to_location_id:ID!,$seat_type_id:ID!,$seat_number:Int!,$edit_id:Int) {
        addCustomerCartTickets(token: $cartToken,trip_id:$trip_id , trip_route_line_id:$trip_route_line_id, from_location_id:$from_location_id, to_location_id:$to_location_id, seat_type_id:$seat_type_id,seat_number:$seat_number ,edit_id:$edit_id) {
            cart {
                id
                token
                promo_code {
                    code
                    active
                }
                tickets {
                    ...TicketData
                    created_at
                }
                created_at
                updated_at
            }
            message
            message_ar
            status
        }
    }
    ${ticketFragment}
`;
