import gql from 'graphql-tag';

const GET_ACTIVE_CITIES = gql`
  query activeCities($isSeasonal: Boolean) {
    activeCities(is_seasonal: $isSeasonal) {
      id
      code
      name_en
      name_ar
      lat
      long
      is_active
      image
      is_seasonal
      seasonal_price
      locations {
        id
        is_active
      }
    }
  }
`;

export default GET_ACTIVE_CITIES;
