import gql from 'graphql-tag';

export default gql`
    mutation createEditTicketOrder(
        $ticketId: ID!
        $cartToken: String!
        $paymentMethod: OrderPaymentMethods!
        $deductionAmount: Float!
        $integrationId: ID
        $walletNumber: String
    ) {
        createEditTicketOrder(
            ticket_id: $ticketId
            cart_token: $cartToken
            deduction_amount: $deductionAmount
            payment_method: $paymentMethod
            integration_id: $integrationId
            wallet_number: $walletNumber
        ) {
            status
            message
            message_ar
            payment_token
            message_signature
            order {
                id
                order_number
                is_auto_confirmed
                payment_url
            }
            edit_ticket {
                deduction_amount
            }
        }
    }
`;
