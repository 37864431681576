import gql from 'graphql-tag';

export default gql`
  query activeCities {
    activeCities {
      id
      code
      name_en
      name_ar
      locations {
        driver_location
        id
        name_en
        name_ar
        lat
        long
      }
      is_active
    }
  }
`;
