import React from 'react';
import styled from '@emotion/styled/macro';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FullScreenContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default FullScreenContainer;
