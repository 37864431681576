import gql from 'graphql-tag';
import { ticketFragment } from './ticket_fragment';

export default gql`
  mutation getCustomerCart($cartToken: String!) {
    getCustomerCart(token: $cartToken) {
      id
      token
      customer_id
      promo_code {
        code
        active
      }
      tickets {
        ...TicketData
          created_at
      }
      created_at
      updated_at
    }
  }
  ${ticketFragment}
`;
