// Auth handlers, login and logout are implemented here

import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { getAuthData } from './auth-manager';
import { notify } from 'utilities';
import i18next from 'i18next';

export const AuthDataContext = createContext(null);

const initialAuthData = {
  token: null,
  customer: null,
  cartToken: null,
  loading: false,
};

const localStorageSetItems = data => {
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (value) {
      if (typeof value === 'string') {
        localStorage.setItem(key, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } else {
      localStorage.removeItem(key);
    }
  });
};

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect(() => {
    const currentAuthData = getAuthData();
    if (currentAuthData && currentAuthData.token) {
      setAuthData(currentAuthData);
    }
  }, []);

  // Logout
  const onLogout = () => {
    window.webengage.user.logout();
    localStorage.removeItem('token');
    localStorage.removeItem('customer');
    localStorage.removeItem('cartToken');
    setAuthData({
      ...initialAuthData,
      loading: false,
    });
    const logOutMessage =
      i18next.language === 'en'
        ? 'Logged out successfuly'
        : 'تم تسجيل الخروج بنجاح';
    notify('success', logOutMessage);
    window.webengage.user.logout();
  };

  // Login
  const onLogin = newAuthData => {
    localStorageSetItems(newAuthData);
    setAuthData(newAuthData);
  };

  const onSetAuthData = newAuthData => {
    localStorageSetItems(newAuthData);
    setAuthData(newAuthData);
  };

  const authDataValue = useMemo(
    () => ({ ...authData, onLogin, onLogout, onSetAuthData }),
    [authData]
  );

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
