import gql from 'graphql-tag';

export const ticketFragment = gql`
  fragment TicketData on Ticket {
    id
    from_date
    seat_number
    price
    original_price
    remaining_cart_time
    seat_type {
      id
      name_en
      name_ar
    }
    routeLine {
      id
    }
    trip {
      id
      ref_code
      access_level
      date
      status
      is_active 
      locations {
        id
        name_en
        name_ar
        pivot {
          time
        }
      }
    }
    from_location {
      id
      code
      name_en
      name_ar
      type
      city {
        id
        code
        name_en
        name_ar
      }
    }
    to_location {
      id
      code
      name_en
      name_ar
      type
      city {
        id
        code
        name_en
        name_ar
      }
    }
  }
`;
