import gql from 'graphql-tag';

const Customer = gql`
  query customer($customerId: ID) {
    customer(id: $customerId) {
      id
      name
      phone
      email
    }
  }
`;

export default Customer;
