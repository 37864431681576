/* This is the login form component. */

import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Input, Spin, Icon } from 'antd';
import { colors } from 'utilities';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { LOGIN } from 'services';
import { Button } from 'components/button';
import { notify } from 'utilities';
import { useAuthDataContext } from 'utilities/auth-context';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
const FormWrapper = styled.div`
  background-color: white;
  padding: 30px 40px;
  border-radius: 20px;
  @media screen and (max-width: 1025px) {
    padding: 40px 35px;
    .ant-row.ant-form-item.u-flexBetween {
      margin-bottom: 50px;
      .ant-form-item-children {
        flex-direction: column-reverse;
        align-items: normal;
        button {
          margin-top: 50px;
          width: 85%;
          margin: 50px auto 0;
        }
        a {
          line-height: 25px;
        }
      }
    }
  }
  .ant-form-item-label > label::after {
    content: none;
  }
  .ant-form-item-required::before {
    content: none;
  }
  .ant-input {
    border-radius: 0;
    @media screen and (max-width: 1025px) {
      font-size: 16px;
    }
  }
  .ant-form-item-label {
    font-family: ${props => props.font};
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: 1025px) {
      text-align: right;
    }
  }
  .ant-form-item-label > label {
    color: ${colors.primaryColor};
    @media screen and (max-width: 1025px) {
      font-size: 16px;
    }
  }
`;

const FormTitle = styled.h1`
  font-family: ${props => props.font};
  color: ${colors.primaryColor};
  font-weight: 500;
  @media screen and (max-width: 1025px) {
    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
  }
`;

const InlineFormItems = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-form-item {
    width: 45%;
    @media screen and (max-width: 1025px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1025px) {
    display: inline-block;
    width: 100%;
    .ant-form-item + .ant-form-item {
      margin-top: 30px;
    }
  }
`;

const EyeIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  // left: 5%;
  right: 5%;
  line-height: 1;
  transform: translateY(-50%);
`;

const RegisterLinkWrapper = styled.div`
  @media screen and (max-width: 1025px) {
    text-align: center;
  }
`;
/**
 * The login form component.
 */
const LoginForm = ({
  setCustomerData,
  setLoginStep,
  form: { getFieldDecorator, validateFields, getFieldValue },
  setStep,
}) => {
  const { t } = useTranslation('login');
  const { language } = i18next;
  const history = useHistory();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [login, { loading }] = useMutation(LOGIN);
  const { cartToken, onLogin } = useAuthDataContext();

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        login({
          variables: values,
          
        })
          .then(res => {
            const {
              data: {
                loginCustomer: {
                  status,
                  message,
                  message_ar,
                  responseCode,
                  token,
                  data: customerData,
                },
              },
            } = res;
            if (status) {
              // onLogin is a function that sets the login data in the local storage to keep the user logged in.
              onLogin({
                token,
                customer: customerData,
                cartToken,
                loading: false,
              });
              notify('success', language === 'en' ? message : message_ar);
              const regex = new RegExp(/^(01|01|00201)[0-2,5]{1}[0-9]{8}/g)
              const isEgyptianPhoneNumber = regex.test(`${customerData['phone']}`);
              window.webengage.user.login( isEgyptianPhoneNumber ? `2${customerData['phone']}` : `${customerData['phone']}`);
              window.webengage.user.setAttribute('we_email', customerData['email']);
              window.webengage.user.setAttribute('we_first_name', customerData['name']);
              window.webengage.user.setAttribute('we_phone', isEgyptianPhoneNumber ? `2${customerData['phone']}` : `${customerData['phone']}` );
              // in case he was not checking out a ticket, the user is taken back to homepage
              if (!setStep) {
                history.push('/');
              }
            } else if (
              /* if the user still needs activation to get access to the website but his data is returned
               *or if the data isn't returned but 11 responseCode determines he's not active
               *
               * This is to handle the inconsistency of API responses of this request
               *
               */
              (!status && customerData) ||
              (!status && responseCode === 11)
            ) {
              notify('error', language === 'en' ? message : message_ar);
              setCustomerData(
                customerData || { phone: getFieldValue('phone') }
              );

              if (setStep) {
                // if the user is checking out, we switch the checkout view
                setStep('verification');
              } else {
                // if the user is logging in normally
                setLoginStep(2);
              }
            } else {
              notify('error', language === 'en' ? message : message_ar);
            }
          })
          .catch(err => {
            console.log(err);
            notify('error', err[language === 'en' ? 'message' : 'message_ar']);
          });
      }
    });
  };

  const font = language === 'en' ? 'FreightSans Pro' : 'GE SS Two';

  return (
    <Spin spinning={loading}>
      <FormWrapper font={font}>
        <FormTitle font={font}>{t('header')}</FormTitle>
        <Form onSubmit={e => handleSubmit(e)}>
          <InlineFormItems>
            <Form.Item label={t('phone')}>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: t('phoneRequired'),
                    len: 11,
                    // //pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(<Input className="forced_ltr forced_english" />)}
            </Form.Item>
            <Form.Item label={t('password')}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: t('passwordRequired'),
                  },
                ],
              })(
                <Input
                  className="forced_ltr forced_english"
                  type={passwordHidden ? 'password' : 'text'}
                />
              )}
              <EyeIconWrapper>
                <Icon
                  type={passwordHidden ? 'eye' : 'eye-invisible'}
                  onClick={() => setPasswordHidden(!passwordHidden)}
                />
              </EyeIconWrapper>
            </Form.Item>
          </InlineFormItems>
          <Form.Item className="u-flexBetween">
            <Button primary htmlType="submit">
              {t('loginButton')}
            </Button>
            <Link to="/forgot-password">{t('forgotPassword')}</Link>
          </Form.Item>
          <RegisterLinkWrapper>
            {t('dontHaveAccount')}{' '}
            {setStep ? (
              <Button 
              clean small 
              onClick={() => {
                setStep('register');
              }}>
                {t('registerAction')}
              </Button>
            ) : (
              <Link to="/register">{t('registerAction')}</Link>
            )}
          </RegisterLinkWrapper>
        </Form>
      </FormWrapper>
    </Spin>
  );
};

export default Form.create({})(LoginForm);
