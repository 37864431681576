import gql from 'graphql-tag';

export default gql`
  mutation cancelUnPaidOrder($orderNumber: String!) {
    cancelUnPaidOrder(order_number: $orderNumber) {
      status
      message
      message_ar
    }
  }
`;
