import gql from 'graphql-tag';

export default gql`
  mutation cancelOrderedTicket($ticketId: ID!) {
    cancelOrderedTicket(ticket_id: $ticketId) {
      status
      message
      message_ar
    }
  }
`;
