/** @jsx jsx */
// import 'antd/dist/antd.css';
import { jsx, Global, css } from '@emotion/core';
import { fontFace } from './font-generation-helper';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const GlobalStyles = () => {
  const { t } = useTranslation('css');
  const { language } = i18next;
  return (
    <Global
      styles={css`
        ${fontFace('GE SS Two', 'GESSTwoLight-Light', '.woff', 300, true)};
        ${fontFace('GE SS Two', 'GESSTwoMedium-Medium', '.woff', 500, true)};
        ${fontFace('FreightSans Pro', 'FreightSans-Pro-Light', '.otf', 300)};
        ${fontFace('FreightSans Pro', 'FreightSans-Pro-Medium', '.otf', 500)};

        html {
          ${t('switchDirection')};
          box-sizing: border-box;
          font-family: ${language === 'en' ? 'FreightSans Pro' : 'GE SS Two'};
          img {
            max-width: 100%;
          }
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          font-family: ${language === 'en' ? 'FreightSans Pro' : 'GE SS Two'};
          padding: 0;
          margin: 0;
        }
        body {
          background: #f6f7f9;
          .ant-calendar {
            /* width: 260px; */
          }
        }

        input,
        button,
        select,
        optgroup,
        textarea {
          font-family: ${language === 'en' ? 'FreightSans Pro' : 'GE SS Two'};
        }
      `}
    />
  );
};

export default GlobalStyles;
