/* This is the cancel ticket component. It is used to cancel a ticket and can be reached when
the user clicks on cancel ticket from my trips on any ticket that's not in the past.  */

import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors, notify } from 'utilities';
import { SingleTicket } from 'components';
import { Button } from 'components/button';
import { useHistory, Redirect } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { cancelOrderedTicket, GET_CANCEL_DEDUCTION_AMOUNT } from 'services';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useAuthDataContext } from 'utilities';
import { Spin } from 'antd';
import { Modal } from 'antd';
import Countdown from 'react-countdown';
import { isMobile } from 'react-device-detect';

const CancelTripContainer = styled.section`
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;

  h1 {
    text-align: center;
    color: ${colors.primaryColor};
    font-size: 32px;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 40px;
  }

  h2 {
    color: ${colors.primaryColor};
    font-size: 22px;
    font-weight: bolder;
  }
`;

const TermsList = styled.ul`
  padding-inline-start: 14px;
  margin-top: 30px;
  margin-bottom: 50px;
  li {
    line-height: 22px;
    color: ${colors.primaryColor};
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 14px;
  }
`;
const DeductionText = styled.p`
    font-size: 20px;
    color: black;
    font-weight: bold;
    span{
      font-size: 24px;
    color: #D3072A;
    }
`;
const counterStyle = {
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  flexDirection: isMobile ? 'column' : 'row',
};

const CancelTrip = () => {
  const { customer } = useAuthDataContext();
  const { phone, email } = customer || {
    id: null,
  };
  const { t } = useTranslation('cancelTrip');
  const { language } = i18next;

  const history = useHistory();
  const ticket = history.location.state && history.location.state.ticket;
  const seat = history.location.state && history.location.state.seat;
  const ticketId = seat && seat.ticketId;
  const [deductionAmount, setDeductionAmount] = useState(0);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [
    getDeductionAmount,
    { data: deductionAmountData, loading: fetchingDeductionAmount, refetch },
  ] = useLazyQuery(GET_CANCEL_DEDUCTION_AMOUNT, {
    fetchPolicy: 'network-only',
    onCompleted: res => {
      if (res.getCancelTicketDeductionAmount.status) {
        setDeductionAmount(
          res.getCancelTicketDeductionAmount.data.deduction_amount
        );
      }
    },
  });
  const [cancelTicket, { submitting, loading }] = useMutation(
    cancelOrderedTicket,
    {
      variables: {
        ticketId: seat && seat.ticketId,
      },
    }
  );

  const submitCancel = () => {
    cancelTicket()
      .then(res => {
        const {
          data: {
            cancelOrderedTicket: { status, message, message_ar },
          },
        } = res;

        if (status) {
          notify('success', language === 'en' ? message : message_ar);
          history.replace('/my-trips#Upcoming', null);
        } else {
          notify('error', language === 'en' ? message : message_ar);
        }
      })
      .catch(err => {});
  };

  if (!ticket || !seat) return <Redirect to="/" />;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      console.log('completed');
      refetch();
      return false;
    } else {
      // Render a countdown
      return (
        <div style={{ marginLeft: 'auto' }}>
          <h3 className={'countDown'} style={{ color: colors.primaryColor }}>
            {t('countDown')}{' '}
            <span style={{ fontSize: 24 }}>
              {minutes}:{seconds}
            </span>
          </h3>
        </div>
      );
    }
  };
  return (
    <CancelTripContainer>
      <h1>{t('header')}</h1>
      <h2>{t('subHeader')}</h2>
      <TermsList>
        <li>{t('policyList.policy1')}</li>
        <li>{t('policyList.policy2')}</li>
        <li>{t('policyList.policy3')}</li>
        <li>{t('policyList.policy4')}</li>
      </TermsList>

      <SingleTicket ticketDetails={ticket} seat={seat} />
      <Spin spinning={loading}>
        <Button
          danger
          disabled={submitting}
          onClick={() => {
            console.log('ticketId', ticketId);
            getDeductionAmount({
              variables: {
                id: ticketId,
              },
            });
            firebase.analytics().logEvent('cancel_ticket', {
              source: `${window.location.href}`,
              phoneNumber: `${phone}`,
              email: `${email}`,
            });
            setCancelConfirmation(true);
          }}
        >
          {t('cancelButton')}
        </Button>
      </Spin>
      <Modal
        visible={cancelConfirmation}
        onCancel={() => setCancelConfirmation(false)}
        title={t('confirmCancel')}
        footer={[]}
      >
        <Spin spinning={fetchingDeductionAmount}>
          <h2
            style={{ color: '#002D59', fontSize: '22px', fontWeight: 'bolder' }}
          >
            {t('subHeader')}
          </h2>
          <TermsList>
            <li>{t('policyList.policy1')}</li>
            <li>{t('policyList.policy2')}</li>
            <li>{t('policyList.policy3')}</li>
            <li>{t('policyList.policy4')}</li>
          </TermsList>
          <DeductionText>
            *{t('deductionAmount')} <span>{deductionAmount + ' ' + t('egp')}</span> {","} {t('sure')} 
          </DeductionText>
          <div style={counterStyle}>
            <Button
              danger
              disabled={submitting}
              onClick={() => {
                firebase.analytics().logEvent('cancel_ticket', {
                  source: `${window.location.href}`,
                  phoneNumber: `${phone}`,
                  email: `${email}`,
                });
                submitCancel();
                setCancelConfirmation(false);
              }}
            >
              {t('cancelButton')}
            </Button>
            <Button
              inverted
              disabled={submitting}
              onClick={() => {
                setCancelConfirmation(false);
              }}
            >
              {t('closeButton')}
            </Button>
            <Countdown
              date={Date.now() + 180000}
              renderer={renderer}
              key={Date.now()}
              onComplete={() => {
                refetch();
                setCancelConfirmation(false);
              }}
            ></Countdown>
          </div>
        </Spin>
      </Modal>
    </CancelTripContainer>
  );
};

export default CancelTrip;
