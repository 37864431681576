import gql from 'graphql-tag';

export default gql`
  mutation createCustomerOrder(
    $customerId: ID!
    $cartToken: String!
    $paymentMethod: OrderPaymentMethods!
    $integrationId: ID
    $walletNumber: String
    $affiliate: String
    $affiliate_data: AffiliateData
  ) {
    createCustomerOrder(
      customer_id: $customerId
      cart_token: $cartToken
      payment_method: $paymentMethod
      integration_id: $integrationId
      wallet_number: $walletNumber
      affiliate:$affiliate
      affiliate_data:$affiliate_data
    ) {
      status
      message
      message_ar
      payment_token
      message_signature
      order {
        id
        payment_url 
        order_number
        is_auto_confirmed
      }
    }
  }
`;
