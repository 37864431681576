import gql from 'graphql-tag';

export default gql`
  query customerTicketsHistory(
    $customerId: ID!
    $historyType: TicketHistoryType!
  ) {
    customerTicketsHistory(
      customer_id: $customerId
      historyType: $historyType
    ) {
      id
      code
      uuid
      status
      status_ar
      from_date
      from_location {
        lat
        long
        id
        name_ar
        name_en
        city {
          name_ar
          name_en
        }
      }
      to_location {
        lat
        long
        id
        name_ar
        name_en
        city {
          name_ar
          name_en
        }
      }
      routeLine {
        id
      }
      kiosk_number
      payment_method
      qr_code_name
      seat_number
      seat_type {
        id
        name_ar
        name_en
      }
      price
      trip {
        id
        ref_code
        date
        ref_code
        busSalon {
          id
          name
          layout {
            key
            rows
            cols
            type
            seat_type_id
            numbering
          }
          seat_types {
            id
            name_en
            name_ar
            rows
            cols
          }
        }
        locations {
          id
          name_en
          name_ar
          pivot {
            time
            date
          }
        }
        tracking_location {
          longitude
          latitude
        }
      }
      from_time
      to_time
      order {
        id
        price
        order_number
        status
      }
    }
  }
`;
