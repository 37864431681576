/**
 * Generate variations of fontface.
 *
 * @param {string} name
 * @param {string} src
 * @param {integer || string} fontWeight
 * @param {string} fontStyle
 *
 */
export const fontFace = (
  name,
  src,
  fontExtension,
  fontWeight = 'normal',
  hasOtherExtensions = false
) => {
  return `
    @font-face {
      font-family: '${name}';
      src: url(${require('../fonts/' + src + fontExtension)});
      ${(() => {
        if (!hasOtherExtensions) return ``;
        else
          return `
          src:
            url(${require('../fonts/' + src + '.woff')}) format('woff'),
            url(${require('../fonts/' + src + '.ttf')}) format('truetype'),
            url(${require('../fonts/' + src + '.woff2')}) format('woff2'),
        `;
      })()}
      font-weight: ${fontWeight};
      font-style: 'normal';
      font-display: fallback;
    }
  `;
};
