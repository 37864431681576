import gql from 'graphql-tag';
import { ticketFragment } from './ticket_fragment';

export default gql`
  mutation addCartPromoCode($token: String!, $promo_code: String!) {
    addCartPromoCodeCustomer(token: $token, promo_code: $promo_code) {
      status
      message
      message_ar
      data {
        id
        token
        created_at
        updated_at
        promo_code {
          code
          active
        }
        tickets {
          ...TicketData
        }
      }
    }
  }
  ${ticketFragment}
`;
