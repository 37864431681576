import gql from 'graphql-tag';

const REGISTER = gql`
  mutation customerFullRegistration(
    $name: String!
    $phone: String!
    $password: String
    $email: String
  ) {
    customerFullRegistration(
      name: $name
      phone: $phone
      password: $password
      email: $email
    ) {
      code
      data {
        id
        name
        phone
        email
        registered
        is_valid
        created_at
        updated_at
      }
    }
  }
`;

export default REGISTER;
