import gql from 'graphql-tag';

export default gql`
  query getCustomerUnpaidOrders($customerId: ID!) {
    getCustomerUnpaidOrders(customer_id: $customerId) {
      id
      payment_method
      price
      order_number
      status
      tickets {
        id
        uuid
        code
      }
      created_at
      updated_at
    }
  }
`;
