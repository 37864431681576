import gql from 'graphql-tag';
import { ticketFragment } from './ticket_fragment';

export default gql`
  mutation removeCartTickets($ticketIds: [ID!]!, $cartToken: String!) {
    removeCartTickets(ticket_ids: $ticketIds, token: $cartToken) {
      id
      token
      promo_code {
        code
        active
      }
      tickets {
        ...TicketData
      }
      created_at
      updated_at
    }
  }
  ${ticketFragment}
`;
