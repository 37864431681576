import gql from 'graphql-tag';

export default gql`
  query getComplaintsReasons {
    complaintsReasons {
      reason_en
      reason_ar
    }
  }
`;
