import React from 'react';
import styled from '@emotion/styled/macro';

import { InfoFooter, ContactUsFooter, LinksFooter } from './components';
import { Container, colors } from 'utilities';
import FooterBackGround from './images/footer.png';
import { isMobile } from 'react-device-detect';
import ArabicLogo from 'scenes/home/images/arabicLogo.png';
import EnglishLogo from '../header/images/logo.svg';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const FooterWrapper = styled.footer`
  background: ${colors.primaryColor} url(${FooterBackGround}) no-repeat bottom
    center;
  color: #fff;
  a {
    color: #fff;
  }
`;
const WrapperContainer = styled.div`
  ${Container};
  display: grid;
  padding:  ${isMobile ? '16px 32px' : '50px 30px 20px 24px'};
  grid-template-columns: '1fr 1fr 1fr';
  grid-template-rows: '1fr 1fr';
  grid-column-gap: 40px;

  svg {
    order: 0;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  @media screen and (max-width: 920px) {
    grid-column-gap: 24px;
  }

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;

    & > svg {
      margin-bottom: 20px;
    }
  }
`;
const InfoFooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ArabicLogoImage = styled.img`
    width: 170px;
    margin-top: 0px;
`

const LogoHandller= styled.div`
  text-align:${isMobile ? "center" : "start"}
`
const Footer = () => {
  const { language } = i18next;
  return (
    <>
      <FooterWrapper>
        <WrapperContainer>
          {language === 'ar' && (
           <LogoHandller>
             <ArabicLogoImage src={ArabicLogo} alt="ArabicLogo" />
             {/* <Details>{t("info.details")}</Details> */}
           </LogoHandller>
          )}
          {language === 'en' && (
            <LogoHandller>
             <ArabicLogoImage src={EnglishLogo} alt="EnglishLogo" />
             {/* <Details>{t("info.details")}</Details> */}
           </LogoHandller>
          )}
          <LinksFooter />
          <ContactUsFooter />
        </WrapperContainer>
        <InfoFooterWrapper>
          <InfoFooter />
        </InfoFooterWrapper>
      </FooterWrapper>
      {/* {isMobile&&(
      <AppPopUp/>
    )} */}
    </>
  );
};

export default Footer;
