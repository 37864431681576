/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const Seaticon = ({ color, width, height }) => {
  return (
    <svg
      viewBox="0 0 31 35"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        fill: ${color};
        max-width: 100%;
        width: ${width + 'px' || '35px'};
        height: ${height + 'px' || '35px'};
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7941 30.323H28.7353V29.7265H25.7941V30.323ZM5.20588 17.4991L5.20588 27.9372H2.26471L2.26471 17.4991C2.26471 17.3345 2.39647 17.2009 2.55882 17.2009H4.91176C5.07412 17.2009 5.20588 17.3345 5.20588 17.4991ZM6.97059 21.9964L6.97059 17.4991C6.97059 16.4493 6.20235 15.5779 5.20588 15.433L5.20588 4.97345C5.20588 3.49363 6.39353 2.28938 7.85294 2.28938L23.1471 2.28938C24.6065 2.28938 25.7941 3.49363 25.7941 4.97345L25.7941 15.433C24.7976 15.5779 24.0294 16.4493 24.0294 17.4991V21.9964C23.9312 21.9803 23.8335 21.9726 23.7353 21.9726L7.26471 21.9726C7.16647 21.9726 7.06882 21.9803 6.97059 21.9964ZM6.97059 24.0602C6.97059 23.8955 7.10235 23.7619 7.26471 23.7619L23.7353 23.7619C23.8976 23.7619 24.0294 23.8955 24.0294 24.0602V30.323L6.97059 30.323L6.97059 24.0602ZM25.7941 17.4991C25.7941 17.3345 25.9259 17.2009 26.0882 17.2009H28.4412C28.6035 17.2009 28.7353 17.3345 28.7353 17.4991L28.7353 27.9372H25.7941L25.7941 17.4991ZM2.26471 30.323H5.20588V29.7265H2.26471V30.323ZM9.32353 32.7088L21.6765 32.7088V32.1124H9.32353V32.7088ZM28.4412 15.4115H27.5588L27.5588 4.97345C27.5588 2.50649 25.58 0.5 23.1471 0.5L7.85294 0.5C5.42 0.5 3.44118 2.50649 3.44118 4.97345L3.44118 15.4115H2.55882C1.42353 15.4115 0.5 16.3479 0.5 17.4991L0.5 31.2177C0.5 31.711 0.895882 32.1124 1.38235 32.1124H7.55941L7.56353 34.5L23.4459 34.5L23.4418 32.1124H29.6176C30.1041 32.1124 30.5 31.711 30.5 31.2177L30.5 17.4991C30.5 16.3479 29.5765 15.4115 28.4412 15.4115Z"
      />
    </svg>
  );
};

export default Seaticon;
Seaticon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
