/* This is the cart component to view details of the selected seats to user during
the booking and checkout process. */

import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from 'utilities/theme';
import { CircleIcon, LocationIcon } from 'components/svgs';
import { Button } from 'components/button';
import { calculateTicketsPrice, restructureTickets, useFormattedTime } from 'utilities';
import { useHistory } from 'react-router-dom';
import { useAuthDataContext } from 'utilities';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Timer from '../svgs/components/timer';
import Countdown from 'react-countdown';
import { isMobile } from 'react-device-detect';
import GuestCheckout from 'scenes/ticketing/checkout/guest-checkout';
import {Modal} from 'antd';

const CartContainer = styled.aside`
    text-align: center;
    flex: 1;
    background: white;
    margin-bottom: 40px;
    margin-inline-start: ${isMobile ? '0px' : '30px'};
    margin-top: 20px;
    min-height: 220px;
    box-shadow: 0 5px 14px 0 rgba(0, 45, 89, 0.04);
    border-radius: 10px;
    @media screen and (max-width: 1025px) {
        margin: 40px 0;
    }
`;

const CartTitle = styled.h2`
    padding: 26px;
    background: ${colors.primaryColor};
    color: white;
    border-radius: 10px;
`;

const CartTrips = styled.section`
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        background-image: linear-gradient(
                90deg,
                #000,
                #000 75%,
                transparent 75%,
                transparent 100%
        );
        background-size: 7px 1px;
        border: none;
    }
`;

const CartTrip = styled.dl`
    padding: 30px;
    font-size: 14px;
    color: ${colors.primaryColor};

    &:not(:last-child) {
        border-bottom: 1px solid black;
    }

    dt,
    dd {
        margin: 0;
        display: block;
        text-align: start;
    }
`;

const TripElement = styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 25px;
    }

    .countDown {
        padding: 10px;
        color: green;
        font-size: 16px;
        font-weight: bold;
    }
`;

const TripPrice = styled(TripElement)`
    dt,
    dd {
        font-weight: bolder;
        font-size: 16px;
    }
`;

const TripActions = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        font-size: 12px;
        margin: 0;
    }
`;

const CartFooter = styled.section`
    padding: 30px;
    position: relative;

    button {
        width: 100%;
        margin-top: 20px;
    }

    &::before,
    ::after {
        content: '';
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background: #f6f7f9;
        position: absolute;
        top: 0;
    }

    &::before {
        left: 0;
        transform: translateY(-50%) translateX(-50%);
    }

    &::after {
        right: 0;
        transform: translateY(-50%) translateX(50%);
    }
`;

const TotalPrice = styled.div`
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: 18px;
        font-weight: bolder;
    }
`;

const EmptyCart = styled.section`
    padding-top: 30px;

    h3 {
        font-size: 18px;
        font-weight: bolder;
        margin-bottom: 16px;
    }
`;

const GroupPriceContainer = styled.div`
    display: flex;
    flex-direction: column;

    dd:first-of-type {
        text-decoration: line-through;
        opacity: 0.6;
    }

    dd:last-of-type {
        color: ${colors.success};
    }
`;

const TotalPriceContainer = styled.div`
    display: flex;
    flex-direction: column;

    h3:first-of-type {
        text-decoration: line-through;
        opacity: 0.6;
    }

    h3:last-of-type {
        color: ${colors.success};
    }
`;

const CartFormattedTime = ({time}) => {
    const {time: formattedTime} = useFormattedTime(time);
    return <dd>{formattedTime}</dd>;
};

const Cart = ({
                  cart,
                  checkout,
                  setTicketToDelete,
                  deductionAmount,
                  editTicket,
                  editTicketPrice,
                  setPrice,
                  setSeatsNumber,
                  setNumersOfSeats,
                  isRound,
                  roundDate,
                  tripDate,
                  paymentMethod
              }) => {
    const {token} = useAuthDataContext();
    const [requierLogin, setRequireLogin] = useState(false);
    const {t} = useTranslation('cart');
    const {language} = i18next;
    const history = useHistory();
      useEffect(()=>{
        if(token){
          setRequireLogin(false)
        }
      }, [token])

    const {tickets} = cart || {tickets: []};
    const groupedTickets = useMemo(() => restructureTickets(tickets), [tickets]);
    const isTickets = tickets.length >= 1 ? true : false;
    const isRoundTickets = Object.keys(groupedTickets).length >= 2 ? true : false;
    const seatsNumbers = () => {
        return cart && cart.tickets.length > 0 ? cart.tickets.length : null;
    }
    setSeatsNumber(seatsNumbers());
    let numbersOfSeats = Object.keys(tickets).map((e) => {
        return tickets[e].seat_number.toLocaleString();

    });

    const fromLocationTime = Object.keys(groupedTickets).map(e => {
        return groupedTickets[e]['fromLocationTime'];
    });
    const toLocationTime = Object.keys(groupedTickets).map(e => {
        return groupedTickets[e]['toLocationTime'];
    });

    const departureTime = useFormattedTime(
        isTickets ? fromLocationTime[0].toLocaleString() : ''
    ).time;
    const returnTime = useFormattedTime(
        isTickets ? toLocationTime[0].toLocaleString() : ''
    ).time;
    const roundTripDepartureTime = useFormattedTime(
        isRoundTickets ? fromLocationTime[1].toLocaleString() : ''
    ).time;
    const roundTripReturnTime = useFormattedTime(
        isRoundTickets ? toLocationTime[1].toLocaleString() : ''
    ).time;


    const oneWayNumberOfSeats = groupedTickets[

        Object.keys(groupedTickets)[0]
        ]?.seats.map(e => {
        return e.seatNumber;
    });

    const twoWayNumberOfSeats =  groupedTickets[

        Object.keys(groupedTickets)[1]
        ]?.seats.map(e => {
        return e.seatNumber;
    });

    const oneWayTicketsId = groupedTickets[
        Object.keys(groupedTickets)[0]
        ]?.seats.map(e => {
        return e.ticketId;
    });

    const TwoWayTicketsId = groupedTickets[
        Object.keys(groupedTickets)[1]
        ]?.seats.map(e => {
        return e.ticketId;
    });
    
    const departureDate = sessionStorage.getItem('departureDate');
    const roundTrip = sessionStorage.getItem('roundTrip') === 'true';
    const isRoundSeatSelected =
        sessionStorage.getItem('isRoundSeatSelected') === 'true';
    const seatType = sessionStorage.getItem('seatType');

    const departurePrice = groupedTickets[Object.keys(groupedTickets)[0]]?.groupPrice;
    const returnPrice =  groupedTickets[Object.keys(groupedTickets)[1]]?.groupPrice;
    numbersOfSeats = numbersOfSeats.toString();

    setNumersOfSeats(numbersOfSeats);

    const deduction = editTicket ? editTicketPrice - deductionAmount : 0;

    // This is used to calculate the current price
    // In case of promo code, the current price will be different from the original price.
    // In other cases, the current price will be the same as the original price.
    const totalPrice =
        useMemo(() => calculateTicketsPrice(groupedTickets, 'groupPrice'), [
            groupedTickets,
        ]) - deduction;

    // This is used to calculate the original price
    const originalTotalPrice =
        useMemo(() => calculateTicketsPrice(groupedTickets, 'originalGroupPrice'), [
            groupedTickets,
        ]) - deduction;

    function updateCart() {
        // todo  getCartData() need more to work
        window.location.reload();
    }

    const isPromocodeApplied = totalPrice !== originalTotalPrice;
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            updateCart();
            return false;
        } else {
            // Render a countdown
            return (
                <span className={'countDown'}>
          {minutes}:{seconds}
        </span>
            );
        }
    };

    function getDate(remaining) {
        if (remaining) return Date.now() + 1000 * remaining;
    }
    setPrice(totalPrice);
    return (
      <CartContainer>
        <CartTitle>{t('header')}</CartTitle>
        {cart && cart.tickets.length > 0 ? (
          <>
            <CartTrips>
              {Object.keys(groupedTickets).map((groupId, tripIndex) => (
                <CartTrip key={groupId}>
                  <TripElement>
                    <dt>
                      <CircleIcon />{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en' ? 'fromCityName' : 'fromCityNameAr'
                        ]
                      }{' '}
                      -{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en'
                            ? 'fromLocationName'
                            : 'fromLocationNameAr'
                        ]
                      }
                    </dt>

                    <CartFormattedTime
                      time={groupedTickets[groupId].fromLocationTime}
                    />
                  </TripElement>
                  <TripElement>
                    <dt>
                      <Timer color={'green'} height={'16px'} width={'20px'} />
                      {t('seatReserved')}
                      <Countdown
                        date={getDate(groupedTickets[groupId].remainingTime)}
                        renderer={renderer}
                      />
                      {t('minute')}
                    </dt>
                  </TripElement>
                  <TripElement>
                    <dt>
                      <LocationIcon />{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en' ? 'toCityName' : 'toCityNameAr'
                        ]
                      }{' '}
                      -{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en'
                            ? 'toLocationName'
                            : 'toLocationNameAr'
                        ]
                      }
                    </dt>
                    <CartFormattedTime
                      time={groupedTickets[groupId].toLocationTime}
                    />
                  </TripElement>
                  <TripElement>
                    <dt>{t('tripDetails.departureDate')}</dt>
                    {groupedTickets[groupId].fromDate && (
                      <dd>
                        {groupedTickets[groupId].fromDate.replace(/-/g, '/')}
                      </dd>
                    )}
                    {!groupedTickets[groupId].fromDate && (
                      <dd>{groupedTickets[groupId].date.replace(/-/g, '/')}</dd>
                    )}
                  </TripElement>
                  {groupedTickets[groupId].seats.map(seat => (
                    <TripElement key={seat.seatNumber}>
                      <dt>
                        {t('tripDetails.seatNumber')} {seat.seatNumber}
                      </dt>
                      <dd>
                        {
                          seat[
                            language === 'en'
                              ? 'seatTypeNameEn'
                              : 'seatTypeName'
                          ]
                        }
                      </dd>
                    </TripElement>
                  ))}
                  <TripPrice>
                    <dt>{t('tripDetails.ticketPrice')}</dt>
                    {groupedTickets[groupId].originalGroupPrice &&
                    groupedTickets[groupId].originalGroupPrice - deduction !==
                      groupedTickets[groupId].groupPrice - deduction ? (
                      <GroupPriceContainer>
                        <dd>
                          {Math.max(
                            0,
                            (
                              groupedTickets[groupId].originalGroupPrice -
                              deduction
                            ).toFixed(2)
                          )}{' '}
                          {t('common:money.pound', {
                            count: Math.max(
                              0,
                              (
                                groupedTickets[groupId].originalGroupPrice -
                                deduction
                              ).toFixed(2)
                            ),
                          })}
                        </dd>{' '}
                        <dd>
                          {Math.max(
                            0,
                            groupedTickets[groupId].groupPrice.toFixed(2)
                          )}{' '}
                          {t('common:money.pound', {
                            count: Math.max(
                              0,
                              groupedTickets[groupId].groupPrice.toFixed(2)
                            ),
                          })}
                        </dd>
                      </GroupPriceContainer>
                    ) : (
                      <dd>
                        {Math.max(
                          0,
                          groupedTickets[groupId].groupPrice.toFixed(2)
                        )}{' '}
                        {t('common:money.pound', {
                          count: Math.max(
                            0,
                            groupedTickets[groupId].groupPrice.toFixed(2)
                          ),
                        })}
                      </dd>
                    )}
                  </TripPrice>
                  {!checkout && (
                    <TripActions>
                      <Button clean small></Button>
                      <Button
                        clean
                        small
                        onClick={() => {
                          setTicketToDelete(groupedTickets[groupId]);
                          window.webengage.track('Delete Ticket Clicked');
                        }}
                      >
                        {t('buttons.deleteButton')}
                      </Button>
                    </TripActions>
                  )}
                </CartTrip>
              ))}
            </CartTrips>
            <CartFooter>
              <TotalPrice>
                <h3>{t('totalPrice')}</h3>
                {isPromocodeApplied ? (
                  <TotalPriceContainer>
                    <h3>
                      {Math.max(0, originalTotalPrice.toFixed(2))}{' '}
                      {t('common:money.pound', {
                        count: Math.max(0, originalTotalPrice.toFixed(2)),
                      })}
                    </h3>{' '}
                    <h3>
                      {Math.max(0, totalPrice.toFixed(2))}{' '}
                      {t('common:money.pound', {
                        count: Math.max(0, totalPrice.toFixed(2)),
                      })}
                    </h3>
                  </TotalPriceContainer>
                ) : (
                  <h3>
                    {totalPrice.toFixed(2)}{' '}
                    {t('common:money.pound', {
                      count: Math.max(0, totalPrice.toFixed(2)),
                    })}
                  </h3>
                )}
              </TotalPrice>
              {!checkout && (
                <Button
                  success
                  onClick={() => {
                    if (!token) {
                      setRequireLogin(true);
                    } else {
                      if (editTicket) {
                        history.push({
                          pathname: `/checkout`,
                          state: {
                            editTicket: true,
                            ticketId: editTicket,
                            deductionAmount: deductionAmount,
                            editedCartToken: cart.token,
                            editTicketPrice: editTicketPrice,
                            totalPrice: totalPrice,
                            paymentMethod: paymentMethod,
                          },
                        });
                      } else history.push('/checkout');
                      window.webengage.track('Booking Intiated', {
                        'From(one way)': `${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'fromCityName'
                          ]
                        } - ${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'fromLocationName'
                          ]
                        }`,
                        'To(one way)': `${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'toCityName'
                          ]
                        } - ${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'toLocationName'
                          ]
                        }`,
                        'One way Departure Date': new Date(departureDate),
                        'No.of Seats': seatsNumbers(),
                        'One way Seat Numbers': `${oneWayNumberOfSeats.toLocaleString()}`,
                        'One way Departure price': Number(departurePrice),
                        'One way Departure Ticket Code': `${oneWayTicketsId}`,
                        'Round Trip': isRound,
                        'One way Departure Time': `${departureTime}`,
                        'One way Seat Class': seatType,
                        'One way No.of Seats':
                          groupedTickets[Object.keys(groupedTickets)[0]].seats
                            .length,
                        'From(two way)':
                          isRound && isRoundSeatSelected
                            ? `${
                                groupedTickets[Object.keys(groupedTickets)[0]][
                                  'toCityName'
                                ]
                              } - ${
                                groupedTickets[Object.keys(groupedTickets)[0]][
                                  'toLocationName'
                                ]
                              }`
                            : '',
                        'To(two way) ':
                          isRound && isRoundSeatSelected
                            ? `${
                                groupedTickets[Object.keys(groupedTickets)[0]][
                                  'fromCityName'
                                ]
                              } - ${
                                groupedTickets[Object.keys(groupedTickets)[0]][
                                  'fromLocationName'
                                ]
                              }`
                            : '',
                        'Two way Departure Date':
                          isRound && isRoundSeatSelected
                            ? new Date(roundDate)
                            : '',
                        'Two Way Seat Numbers':
                          isRound && isRoundSeatSelected
                            ? `${twoWayNumberOfSeats.toLocaleString()}`
                            : '',
                        'Two way Departure price':
                          isRound && isRoundSeatSelected
                            ? Number(returnPrice)
                            : '',
                        'Two way Departure Time':
                          isRound && isRoundSeatSelected
                            ? `${roundTripDepartureTime}`
                            : '',
                        'Two way Seat Class':
                          isRound && isRoundSeatSelected ? seatType : '',
                        'Two way Departure Ticket Code':
                          isRound && isRoundSeatSelected
                            ? `${TwoWayTicketsId}`
                            : '',
                        'Two way No.of Seats':
                          isRound && isRoundSeatSelected
                            ? groupedTickets[Object.keys(groupedTickets)[1]]
                                .seats.length
                            : '',
                        'Total Price': Number(totalPrice),
                        Currency: `EGP`,
                      });
                      sessionStorage.setItem('isRounded', isRound);
                      sessionStorage.setItem('roundTrip', roundTrip);
                      sessionStorage.setItem('roundDate', roundDate);
                      sessionStorage.setItem('departureTime', departureTime);
                      sessionStorage.setItem('returnTime', returnTime);
                      sessionStorage.setItem(
                        'departureNofSeats',
                        groupedTickets[Object.keys(groupedTickets)[0]].seats
                          .length
                      );
                      sessionStorage.setItem(
                        'returnNofSeats',
                        groupedTickets[Object.keys(groupedTickets)[1]]?.seats
                          .length
                      );
                      sessionStorage.setItem(
                        'oneWayNumberOfseats',
                        oneWayNumberOfSeats.toLocaleString()
                      );
                      sessionStorage.setItem(
                        'twoWayNumberOfseats',
                        twoWayNumberOfSeats?.toLocaleString()
                      );
                      sessionStorage.setItem('returnPrice', returnPrice);
                      sessionStorage.setItem('departurePrice', departurePrice);
                      sessionStorage.setItem(
                        'departureTicketCode',
                        tickets[Object.keys(tickets)[0]].id
                      );
                      sessionStorage.setItem(
                        'returnTicketCode',
                        tickets[Object.keys(tickets)[1]]?.id
                      );
                      sessionStorage.setItem(
                        'From',
                        `${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'fromCityName'
                          ]
                        } - ${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'fromLocationName'
                          ]
                        }`
                      );
                      sessionStorage.setItem(
                        'To',
                        `${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'toCityName'
                          ]
                        } - ${
                          groupedTickets[Object.keys(groupedTickets)[0]][
                            'toLocationName'
                          ]
                        }`
                      );
                      sessionStorage.setItem(
                        'roundTripDepartureTime',
                        roundTripDepartureTime
                      );
                      sessionStorage.setItem(
                        'roundTripReturnTime',
                        roundTripReturnTime
                      );
                      sessionStorage.setItem('oneWayTicketId', oneWayTicketsId);
                      sessionStorage.setItem(
                        'twoWayTicketsId',
                        TwoWayTicketsId
                      );
                    }
                  }}
                >
                  {t('buttons.bookNowButton')}
                </Button>
              )}
            </CartFooter>
          </>
        ) : (
          <EmptyCart>
            <h3>{t('emptyCart.sorryMessage')}</h3>
            <strong>{t('emptyCart.cartIsEmpty')}</strong>
          </EmptyCart>
        )}
        <Modal
          visible={requierLogin}
          onCancel={() => setRequireLogin(false)}
          footer={[]}
        >
          <GuestCheckout />
        </Modal>
      </CartContainer>
    );
};

export default Cart;
