/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const FacebookLogo = ({ color }) => {
  return (
    <svg
      width="11"
      height="22"
      viewBox="0 0 11 22"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        fill: ${color || '#FFF'};
        max-width: 100%;
        height: 22px;
        width: 12px;
      `}
    >
      <path
        d="M7.315 22H2.437V10.999H0V7.208h2.437V4.932C2.437 1.84 3.813 0 7.723 0h3.255v3.792H8.944c-1.523 0-1.623.53-1.623 1.518l-.007 1.898H11l-.431 3.79H7.314V22z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default FacebookLogo;

FacebookLogo.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
