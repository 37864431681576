// This component simply represents private routes.
// It checks if the user is authenticated and his token is valid, then render the route.
// If the user isn't authenticated, then he'll be redirected to home.
// This is to prevent guests from accessing unauthorized routes.

import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthDataContext } from 'utilities/auth-context';
import { Spin } from 'antd';

const PrivateRoute = ({
  children,
  location: { pathname },
  privateRoutes: { authenticatedRoutes, guestRoutes },
}) => {
  const { token, loading } = useAuthDataContext();

  if (loading) return <Spin>{children}</Spin>;

  if (authenticatedRoutes.includes(pathname)) {
    return <>{token ? children : null}</>;
  } else if (guestRoutes.includes(pathname)) {
    return <>{!token ? children : null}</>;
  } else
    return (
      <Redirect
        to={{
          pathname: '/ticketing',
          state: { from: pathname },
        }}
      />
    );
};

export default PrivateRoute;
