/* This is the verification form component used to verify a user. The user can
see this view if he registered but haven't verified his account or if he made 
a forgot passward request. In both cases the user is sent a code and have to 
verify his account before proceeding. */

import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Form, Spin, Input, Col, Row, Icon } from 'antd';
import { colors, notify } from 'utilities';
import styled from '@emotion/styled/macro';
import { VERIFY, RESEND, RESET_PASSWORD, CUSTOMER_CODE } from 'services';
import { Button } from 'components/button';
import { FormContainer } from 'utilities';
import ReactCodeInput from 'react-code-input';
import { useAuthDataContext } from 'utilities/auth-context';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isAndroid } from 'react-device-detect';
import {
  useCounter,
} from '../../../hooks/useCounter';

const FormWrapper = styled.div`
  ${FormContainer};
  margin-top: 40px;
  background-color: white;
  padding: 30px 40px;
  border-radius: 20px;
  p {
    text-align: center;
  }
  .react-code-input {
    display: flex !important;
    justify-content: center !important;
  }
  .ant-form-item-label > label::after {
    content: none;
  }
  .ant-form-item-required::before {
    content: none;
  }
  .ant-input {
    border-radius: 0;
  }
  .ant-form-item-label {
    font-family: ${props => props.font};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .ant-form-item-label > label {
    color: ${colors.primaryColor};
  }
`;

const FormTitle = styled.div`
  display : flex;
  justify-content: space-between;
  align-items: center;
  h1{  
  font-family: ${props => props.font};
  color: ${colors.primaryColor};
  font-weight: 500;
  margin-bottom: 0px
  }
`;

const InlineFormItems = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-form-item {
    width: 100%;
  }
`;

const EyeIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  // left: 5%;
  right: 5%;
  line-height: 1;
  transform: translateY(-50%);
`;
const numberOfSteps = -1;
const delay = 1000;
const stopValue = 0;
const autoStart = true;
let numberOfSeconds = 60;
let numberOfMinutes = 9;
const VerificationForm = ({
  setStep,
  form: { getFieldDecorator, validateFields, setFieldsValue },
  customerData,
  resetPassword,
  guest
}) => {
  const [initialValue, setInitialValue] = useState(numberOfSeconds);
  const {
    counter,
    startCounter,
  } = useCounter(initialValue, numberOfSteps, delay, stopValue, autoStart);
  const { t } = useTranslation('verification');
  const { language } = i18next;
  const history = useHistory();
  const { phone } = customerData || {};
  const regex = new RegExp(/^(01|01|00201)[0-2,5]{1}[0-9]{8}/g)
  const isEgyptianPhoneNumber = regex.test(`${phone}`);
  const [
    getCustomerCodeData
  ] = useLazyQuery(CUSTOMER_CODE, {
    variables: {
      phone,
    },
    onCompleted: newCustomerCodeData => {
      const codeExpiryDate =
        newCustomerCodeData &&
        newCustomerCodeData.getCustomerCode.data.expiry_date;
      setTimeLeft(calculateTimeLeft(codeExpiryDate));
    },
  });
  const getOtp = () => {
    const { phone } = customerData;
    resend({
      variables: {
        phone,
      },
    }).then(res => {
      getCustomerCodeData();
      const {
        data: {
          resendCode: { status, message, message_ar },
        },
      } = res;
      notify(
        status ? 'success' : 'error',
        language === 'en' ? message : message_ar
      );
    });
  }
  const returnToMinutesAndSeconds = (counter) => {
    const seconds = counter % 60; let updateTime = null;
    const numberOfDigits = Math.floor(Math.log10(seconds) + 1);
    if (numberOfDigits > 1) {
      updateTime = `0${Math.floor(counter / 60)}:${`${counter % 60}`}`;
    } else {
      updateTime = `0${Math.floor(counter / 60)}:${`0${counter % 60}`}`;
    }
    return updateTime;
  };
  const handleInitialValue = () => {
    setInitialValue(numberOfSeconds * numberOfMinutes);
  }
  useEffect(() => {
    handleInitialValue();
  }, [])
  // Function to calculate the time left for the verification code to expire
  // Code is available from the response of the mutation.
  // This function just compares the expiry date with the current date and views the time left.
  const calculateTimeLeft = codeExpiryDate_ => {
    const nowUTC = moment.utc();
    const codeExpiryUTC = moment.utc(codeExpiryDate_);
    const difference = codeExpiryUTC.diff(nowUTC);

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minutes: String(Math.floor((difference / 1000 / 60) % 10)).padStart(
          2,
          '0'
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
        milliseconds: difference,
      };
    }

    return timeLeft;
  };

  const [passwordHidden, setPasswordHidden] = useState(true);
  const [setTimeLeft] = useState(null);

  const [verify, { loading: verifyLoading }] = useMutation(
    VERIFY
  );

  const [resend, { loading: resendLoading }] = useMutation(
    RESEND
  );

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);

  const { cartToken, onLogin,token } = useAuthDataContext();

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) {
        window.webengage.track("OTP Verified", {
          "Status": false , 
      })
        return;
      };
      const { pin: code, new_password: password } = values;

      let action;
      if (resetPassword) {
        action = resetPasswordMutation({
          variables: {
            code,
            phone,
            password,
          },
        });
      } else {
        action = verify({
          variables: {
            code,
            phone,
          },
        });
      }

      action.then(res => {
        const {
          data: {
            response: { status, message, message_ar, token, data: customer },
          },
        } = res;
        if (status && token) {
          onLogin({
            token,
            customer,
            cartToken,
            loading: false,
          });
          if(!guest){
            notify('success', language === 'en' ? message : message_ar);
          }
          if(guest){
            history.push('/checkout');
          }
          window.webengage.track("OTP Verified", {
            "Status": true , 
          });
          window.webengage.track("User Registered", {
            "First Name": `${customer.name}`,
            "Phone Number": isEgyptianPhoneNumber ? `2${customer.phone}` : `${customer.phone}`,
            "Email": `${customer.email}`,
          });
          window.webengage.user.login( isEgyptianPhoneNumber ? `2${customerData['phone']}`: `${customerData['phone']}`);
          window.webengage.user.setAttribute('we_email', customerData['email']);
          window.webengage.user.setAttribute('we_first_name', customerData['name']);
          window.webengage.user.setAttribute('we_phone', isEgyptianPhoneNumber ? `2${customerData['phone']}` : `${customerData['phone']}` );
          if (!setStep) {
            window.location.replace('/');
          }
        } else {
          notify('error', language === 'en' ? message : message_ar);
          window.webengage.track("OTP Verified", {
            "Status": false , 
        })
        }
      });
    });
  };

  const font = language === 'en' ? 'FreightSans Pro' : 'GE SS Two';

  return (
    <Spin spinning={resendLoading || verifyLoading}>
      <FormWrapper font={font}>
        <FormTitle font={font}>
         <h1>{t('header')}</h1> 
          <Icon
            type={language === 'ar' ? 'arrow-left' : 'arrow-right'}
            style={{ fontSize: 20, color: colors.primaryColor }}
            onClick={() => setStep('login')}
          />
        </FormTitle>
        {guest ? <p>{t('geustInstructions')}</p> : <p>{t('instructions')}</p>}
        <Form onSubmit={e => handleSubmit(e)}>
          <InlineFormItems>
            <Form.Item className="forced_ltr">
              {getFieldDecorator('pin', {
                initialValue: undefined,
                rules: [
                  {
                    required: true,
                    len: 4,
                    message: t('pinError'),
                  },
                ],
              })(
                <ReactCodeInput
                  inputStyle={
                    isAndroid
                      ? {}
                      : {
                          fontSize: '32px',
                          marginRight: '12px',
                          textAlign: 'center',
                          width: language === 'en' ? 'inherit' : ' 50px',
                          borderRadius: '8px',
                        }
                  }
                  type="number"
                  fields={4}
                />
              )}
            </Form.Item>
          </InlineFormItems>
          {counter >= 0 && (
            <div>
              <span>{t('resendTimer')}</span>{' '}
              <span dir="ltr" className="forced_english">
                {returnToMinutesAndSeconds(counter)}
              </span>
            </div>
          )}
          {counter === initialValue && (
            <Button
              clean
              htmlType="button"
              onClick={e => {
                getOtp();
                startCounter();
                e.preventDefault();
              }}
            >
              {t('resendButton')}
            </Button>
          )}
          {resetPassword && (
            <Row>
              <Col>
                <Form.Item label={t('password')}>
                  {getFieldDecorator('new_password', {
                    rules: [
                      {
                        required: true,
                        message: t('passwordValidation'),
                        min: 6,
                      },
                    ],
                  })(
                    <Input
                      className="forced_ltr forced_english"
                      type={passwordHidden ? 'password' : 'text'}
                      autoComplete="new-password"
                    />
                  )}
                  <EyeIconWrapper>
                    <Icon
                      type={passwordHidden ? 'eye' : 'eye-invisible'}
                      onClick={() => setPasswordHidden(!passwordHidden)}
                    />
                  </EyeIconWrapper>
                </Form.Item>
              </Col>
            </Row>
          )}
          <div style={{ textAlign: 'center', padding:"10px 0px"}}>
            <h2>
              <a
                style={{ textDecoration: 'underline', color: '#262625' }}
                href={
                  'https://api.whatsapp.com/message/WZMPTQU22FA2A1?autoload=1&app_absent=0'
                }
                target={'_blank'}
              >
              {t("callOption")}{' '}{t("whatsapp")}
              </a>
            </h2>
          </div>
          <Form.Item>
            <Button primary>
              {resetPassword ? t('changePassword') : !token? t('Checkout') :  t('loginButton')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Spin>
  );
};

export default Form.create({})(VerificationForm);
