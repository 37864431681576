import gql from 'graphql-tag';

const markNotificationRead = gql`
    mutation markNotificationRead{
        markNotificationRead{
            status
            message
        }
    }
`;

export default markNotificationRead;
