// This is the search trip form. It consists of a form and a submit handler.
// It is the search bar that appears in the middle of the home screen.
// It is used as well in the search results screem.
// The user uses this component to search for a trip.

import React, {  useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Select, Spin, Checkbox } from 'antd';
import { DatePicker } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/ar';
import styled from '@emotion/styled/macro';
import { AllActiveLocations } from 'services';
import DownArrow from 'components/header/images/arrow-down.svg';
import { Button } from 'components/button';
import { colors } from 'utilities/theme';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
moment.locale('en');

const Search = styled.div`
  font-family: ${props => props.font};
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1280px) {
    background-color: #fff;
    border-radius: 10px;
  }
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const Title = styled.p`
  display: inline-block;
  font-weight: 500;
  color: #002d59;
  font-size: 18px;
  margin-bottom: 0;
  margin-inline-start: 10px;
`;

const SubmitButtonWrapper = styled.div`
  button {
    width: 85%;
    display: block;
    margin: 0 auto;
    max-width: 350px;
  }
`;

const { Option, OptGroup } = Select;

const Field = styled.div`
  display: inline-block;
  min-width: 230px;
  min-height: 80px;
  padding: 5px 20px;
  position: relative;
  width: 25%;
  text-align: start;

  @media (max-width: 1025px) {
    width: 100%;
    margin: 0 auto;
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
    }
  }

  @media (min-width: 1280px) {
    & + div {
      &::before {
        content: '';
        height: 77px;
        border: 1px solid #002d59;
        opacity: 0.1;
        position: absolute;
        top: 12px;
        ${props => props.start}: 0;
      }
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;

    & + div {
      margin-top: 20px;
    }
  }

  @media (max-width: 620px) {
    & + div {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1025px) {
    .ant-col.ant-form-item-label {
      text-align: start;
    }
  }
`;

const SelectInput = styled.div`
  .ant-select-selection {
    color: #002d59;
    font-size: 16px;
    line-height: 1.25;
    border: 0;
    background-color: inherit;
    width: 100%;
    padding: 2px 5px;

    &__rendered {
      margin: 0;
      padding: 2px 5px;
    }

    &__placeholder {
      text-align: start;
    }

    &-selected-value {
      float: ${props => props.start};
    }

    &::after {
      content: '';
      background: url(${DownArrow}) 50% 50% no-repeat;
    }
  }
  .ant-select {
    width: 100% !important;
  }

  .ant-select-arrow {
    color: ${colors.primaryColor};
    left: ${props => props.left};
    right: ${props => props.right};
  }
`;

const InputField = styled.div`
  input {
    color: #002d59;
    font-size: 16px;
    line-height: 1.25;
    border: 0;
    width: 100%;
    padding: 2px 8px;
  }
  .ant-calendar-picker-icon {
    right: ${props => props.right};
    left: ${props => props.left};
    top: 11px;
    color: ${colors.primaryColor};
    height: 22px;
    width: 22px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .ant-calendar-picker {
    width: 100%;

    &-clear {
      display: none !important;
    }
  }
`;

const SearchTrips = ({
        form: {
          getFieldDecorator,
          getFieldValue,
          validateFields,
          setFieldsValue,
          resetFields,
        },
        searchParams = {},
        children,
      }) => {
        const { t } = useTranslation('filterTrip');
        const { language } = i18next;
        const history = useHistory();
        
        const {
          from,
          to,
          trip_date,
          round_trip_date,
          edit_ticket,
          payment_method
        } = searchParams;
        const initialRoundTripState = round_trip_date ? true : false;
        const [roundTrip, handleRoundTrip] = useState(initialRoundTripState);
        const { loading, error, data } = useQuery(AllActiveLocations);
        const nameLang = `name_${language}`;
        // TODO: handle error in a better way
        if (error) return `Error! ${error.message}`;
        
        const tripDate =
        getFieldValue('trip_date') ||
        (trip_date ? moment(trip_date) : undefined);
        const fromLocation = {
          city: '',
          station: '',
        };
        const toLocation = {
          city: '',
          station: '',
        };
        

        const fullTripDate = trip_date && moment(trip_date).locale(language);
        const fullRoundTripDate =
          round_trip_date && moment(round_trip_date).locale(language);

        const { activeLocations } = data || { activeLocations: [] };
        const availableLocations = activeLocations.reduce(
          (result, location) => {
            const cityName = location.city[nameLang];
            const cityId = location.city.id;
            if (from === location.id) {
              fromLocation.city = cityName;
              fromLocation.station = location[nameLang];
            }

            if (to === location.id) {
              toLocation.city = cityName;
              toLocation.station = location[nameLang];
            }

            if (!Object.keys(result).includes(cityName)) {
              result[cityName] = [{ ...location, city_id: cityId }];
            } else {
              result[cityName].push({ ...location, city_id: cityId });
            }
            return result;
          },
          {}
        );

        const fromCityId = getFieldValue('from');
        let fromCityName;
        Object.keys(availableLocations).forEach(cityName => {
          availableLocations[cityName].forEach(location => {
            if (location.id === fromCityId) {
              fromCityName = cityName;
              return;
            }
          });
        });
        const toCityId = getFieldValue('to');
        let toCityName;
        Object.keys(availableLocations).forEach(cityName => {
          availableLocations[cityName].forEach(location => {
            if (location.id === toCityId) {
              toCityName = cityName;
              return;
            }
          });
        });
        let FromLocationName;
        Object.keys(availableLocations).forEach(cityName => {
          availableLocations[cityName].forEach(location => {
            if (location.id === fromCityId) {
              FromLocationName = location.name_en;
              return;
            }
          });
        });
        let toLocationName;
        Object.keys(availableLocations).forEach(cityName => {
          availableLocations[cityName].forEach(location => {
            if (location.id === toCityId) {
              toLocationName = location.name_en;
              return;
            }
          });
        });
        const handleSearchTrips = e => {
          e.preventDefault();
          sessionStorage.clear();
          const formFields = ['from', 'to', 'trip_date'];
          if (roundTrip) formFields.push('round_trip_date');
          validateFields(formFields, (err, fieldsValue) => {
            if (!err) {
              // setFormValues(fieldsValue);
              const { from, to, trip_date, round_trip_date } = fieldsValue;
              const tripDateFormatted = trip_date.format('YYYY-MM-DD');
              const roundTripDateFormatted =
                roundTrip && round_trip_date.format('YYYY-MM-DD');
              let edit_ticket_ = edit_ticket;
              if (edit_ticket === undefined) edit_ticket_ = '';
              let payment_method_ = payment_method
              if (payment_method === undefined) payment_method_  = '';
              const searchParams = roundTrip
                ? `/ticketing?from=${from}&to=${to}&trip_date=${tripDateFormatted}&round_trip_date=${roundTripDateFormatted}&edit_ticket=${edit_ticket_}&payment_method=${payment_method_}`
                : `/ticketing?from=${from}&to=${to}&trip_date=${tripDateFormatted}&edit_ticket=${edit_ticket_}&payment_method=${payment_method_}`;
              history.push(searchParams);
                window.webengage.track(isIndex ?'Bus Searched' : 'Search Modified', {
                  "From" :`${fromCityName}-${FromLocationName}`,
                  "To" : `${toCityName}-${toLocationName}`,
                  "Departure Date" : new Date(tripDateFormatted),
                  "Return Date" : roundTrip ?  new Date(roundTripDateFormatted) :null,
                  "Round Trip" :  roundTrip,
                  });
                  sessionStorage.setItem('departureDate', new Date(tripDateFormatted))
                  sessionStorage.setItem('returnDate', roundTrip ?  new Date(roundTripDateFormatted) :null)
                  sessionStorage.setItem("roundTrip" , roundTrip)
            } else {
              console.log(err);
            }
          });
        };

        const isIndex =
          (history && history.location && history.location.pathname === '/') ||
          (history && history.location && history.location.pathname === '/home') ||
          (history &&
            history.location &&
            history.location.pathname === `/destinations/Dahab`) ||
          (history &&
            history.location &&
            history.location.pathname === `/destinations/Hurghada`) ||
          (history &&
            history.location &&
            history.location.pathname === `/destinations/North-Coast`) ||
            (history &&
              history.location &&
              history.location.pathname === `/destinations/sharm-el-sheikh`);

        const childrenToRender =
          !loading &&
          children &&
          children(
            fromLocation,
            toLocation,
            fullTripDate.format('dddd  YYYY/MM/DD'),
            fullRoundTripDate && fullRoundTripDate.format('dddd  YYYY/MM/DD')
          );

        
        const start = language === 'en' ? 'left' : 'right';
        const font = language === 'en' ? 'FreightSans Pro' : 'GE SS Two';
        const left = language === 'en' ? 'auto' : '10px';
        const right = language === 'en' ? '10px !important' : 'auto !important';
        const changeSelect = fromId => {
          const toId = getFieldValue('to');
          let from_location_ = data.activeLocations.find(
            location => location.id === fromId
          );
          let to_location_ = toId
            ? data.activeLocations.find(location => location.id === toId)
            : null;

          if (to_location_ && from_location_.city.id === to_location_.city.id) {
            setFieldsValue({ to: undefined });
          }
        };
        const ComponentData = (
          <Form className="form-wrapper" onSubmit={handleSearchTrips}>
            <Search font={font}>
              <Field start={start}>
                <SelectInput start={start} left={left} right={right}>
                  <Form.Item label={<Title>{t('fields.from')}</Title>}>
                    {getFieldDecorator('from', {
                      initialValue: from,
                      rules: [
                        {
                          required: true,
                          message: t('validations.fieldValidation'),
                        },
                      ],
                    })(
                      <Select
                        notFoundContent="loading ..."
                        onChange={changeSelect}
                        placeholder={t('placeholders.fromPlaceholder')}
                      >
                        {Object.keys(availableLocations).map(
                          (cityName, cityIndex) => (
                            <OptGroup key={'city' + cityIndex} label={cityName}>
                              {availableLocations[cityName]
                                .filter(city => city.driver_location !== true)
                                .map((location, locationIndex) => {
                                  return (
                                    <Option
                                      key={'location' + locationIndex}
                                      value={location.id}
                                      style={{
                                        color: '#002D59',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {location[nameLang]}
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          )
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </SelectInput>
              </Field>
              <Field start={start}>
                <SelectInput start={start} left={left} right={right}>
                  <Form.Item label={<Title>{t('fields.to')}</Title>}>
                    {getFieldDecorator('to', {
                      initialValue: to,
                      rules: [
                        {
                          required: true,
                          message: t('validations.fieldValidation'),
                        },
                      ],
                    })(
                      <Select
                        notFoundContent="loading ..."
                        placeholder={t('placeholders.toPlaceholder')}
                      >
                        {Object.keys(availableLocations)
                          .filter(cityName => cityName !== fromCityName)
                          .map((cityName, cityIndex) => (
                            <OptGroup
                              key={'cityKey' + cityIndex}
                              label={cityName}
                            >
                              {availableLocations[cityName]
                                .filter(city =>
                                  roundTrip === true
                                    ? city.driver_location !== true
                                    : city
                                )
                                .map((location, locationIndex) => (
                                  <Option
                                    key={'locationKey' + locationIndex}
                                    value={location.id}
                                    style={{
                                      color: '#002D59',
                                    }}
                                  >
                                    {location[nameLang]}
                                  </Option>
                                ))}
                            </OptGroup>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </SelectInput>
              </Field>
              <Field start={start}>
                <InputField left={left} right={right}>
                  <Form.Item label={<Title>{t('fields.departureDate')}</Title>}>
                    {getFieldDecorator('trip_date', {
                      initialValue: tripDate,
                      rules: [
                        {
                          type: 'object',
                          required: true,
                          message: t('validations.fieldValidation'),
                        },
                      ],
                    })(
                      <DatePicker
                        style={{ width: '100%', border: 'none' }}
                        placeholder={t('placeholders.departureDatePlaceholder')}
                        disabledDate={current => {
                          return current && current < moment().startOf('day');
                        }}
                      />
                    )}
                  </Form.Item>
                </InputField>
              </Field>
              {roundTrip && (
                <Field start={start}>
                  <InputField left={left} right={right}>
                    <Form.Item label={<Title>{t('fields.returnDate')}</Title>}>
                      {getFieldDecorator('round_trip_date', {
                        initialValue: round_trip_date
                          ? moment(round_trip_date)
                          : undefined,
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (!tripDate)
                                callback(
                                  t('validations.returnDateValidation2')
                                );
                              else if (!value)
                                callback(t('validations.fieldValidation'));
                              else if (value.valueOf() < tripDate.valueOf())
                                callback(
                                  t('validations.returnDateValidation1')
                                );
                              else callback();
                            },
                          },
                        ],
                      })(
                        <DatePicker
                          style={{ width: '100%', border: 'none' }}
                          placeholder={t('placeholders.returnDatePlaceholder')}
                          disabled={!tripDate}
                          disabledDate={endValue => {
                            const startValue = tripDate;
                            if (!endValue || !startValue) {
                              return false;
                            }
                            return endValue.valueOf() <= startValue.valueOf();
                          }}
                        />
                      )}
                    </Form.Item>
                  </InputField>
                </Field>
              )}
              <Field start={start}>
                <SubmitButtonWrapper>
                  <Form.Item>
                    <Checkbox
                      defaultChecked={round_trip_date ? true : false}
                      onChange={e => {
                        handleRoundTrip(e.target.checked);
                        resetFields();
                      }}
                    >
                      {t('fields.roundTrip')}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button primary htmlType="submit">
                      {isIndex
                        ? t('buttons.searchButton')
                        : t('buttons.changeSearchButton')}
                    </Button>
                  </Form.Item>
                </SubmitButtonWrapper>
              </Field>
            </Search>
          </Form>
        );

        return isIndex ? (
          <div>
            {ComponentData}
            {childrenToRender}
          </div>
        ) : (
          <Spin spinning={loading}>
            {ComponentData}
            {childrenToRender}
          </Spin>
        );
      };;

const SearchTripsForm = Form.create({})(SearchTrips);

export default SearchTripsForm;
