import gql from 'graphql-tag';

const VERIFY = gql`
  mutation validateCustomer($phone: String!, $code: Int!) {
    response: validateCustomer(phone: $phone, code: $code) {
      status
      responseCode
      message
      message_ar
      token
      code
      data {
        id
        name
        registered
        is_valid
        email
        phone
      }
    }
  }
`;

export default VERIFY;
