import gql from 'graphql-tag';

export const GET_CANCEL_DEDUCTION_AMOUNT = gql`
  query getCancelTicketDeductionAmount($id: ID!) {
    getCancelTicketDeductionAmount(id: $id) {
      status
      message
      message_ar
      data {
        ticket {
          id
        }
        deduction_amount
      }
    }
  }
`;

export default GET_CANCEL_DEDUCTION_AMOUNT;
