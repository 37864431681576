import gql from 'graphql-tag';

export default gql`
  query contactUsReasons {
    contactUsReasons {
      reason_en
      reason_ar
    }
  }
`;
