// This is the complaint form. It consists of a form and a submit handler.

import React from 'react';
import styled from '@emotion/styled/macro';
import { notify, useAuthDataContext } from 'utilities';
import { Input, Form, Select, Spin } from 'antd';
import { Button } from 'components/button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { getComplaintsReasons, sendComplaint } from 'services';
import { FormContainer } from 'utilities';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const { Option } = Select;
const { TextArea } = Input;

const ComplaintContainer = styled.section`
  ${FormContainer}

  .ant-select-arrow {
    ${props => props.end}: 11px;
    ${props => props.start}: auto;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    text-align: start;
  }

  .ant-select-selection-selected-value {
    float: ${props => props.start};
  }
`;

const ComplaintForm = ({
  form: { getFieldDecorator, validateFields },
  location: { search },
}) => {
  const { t } = useTranslation('complaint');
  const { language } = i18next;

  const history = useHistory();
  if (!history.location.state || !history.location.state.ticketId)
    history.push('/');

  const { data } = useQuery(getComplaintsReasons);
  const [submitComplaint, { loading: submitting }] = useMutation(
    sendComplaint
  );

  const { customer } = useAuthDataContext();
  const { id: customerId } = customer || {
    id: null,
  };

  const ticketId = history.location.state && history.location.state.ticketId;

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        submitComplaint({
          variables: {
            ...values,
            customerId,
            ticketId,
          },
        })
          .then(res => {
            const {
              data: {
                sendComplaint: { status, message_ar, message },
              },
            } = res;
            if (status) {
              notify('success', language === 'en' ? message : message_ar);
              history.replace('/my-trips#Past', null);
            } else {
              notify('error', language === 'en' ? message : message_ar);
            }
          })
          .catch(err => {});
      }
    });
  };

  const start = language === 'en' ? 'left' : 'right';
  const end = language === 'en' ? 'right' : 'left';

  return (
    <ComplaintContainer start={start} end={end}>
      <h1>{t('header')}</h1>
      <Form onSubmit={e => handleSubmit(e)}>
        <Form.Item label={t('fields.complaintReason')}>
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: t('validations.dropDownValidation'),
              },
            ],
          })(
            <Select
              placeholder={t('placeholders.dropDownPlaceholder')}
              size="large"
            >
              {data &&
                data.complaintsReasons &&
                data.complaintsReasons.map((reason, reasonIndex) => (
                  <Option
                    key={'reason' + reasonIndex}
                    value={reason[`reason_${language}`]}
                  >
                    {reason[`reason_${language}`]}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('fields.complaintDetails')}>
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: t('validations.textBoxValidation'),
              },
            ],
          })(
            <TextArea
              rows="4"
              placeholder={t('placeholders.textBoxPlaceholder')}
            ></TextArea>
          )}
        </Form.Item>
        <Form.Item>
          <Spin spinning={submitting}>
            <Button primary disabled={submitting}>
              {t('sendButton')}
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    </ComplaintContainer>
  );
};

export default Form.create({})(ComplaintForm);
