import gql from 'graphql-tag';

export default gql`
  mutation requestPrivateBus(
    $name: String!
    $phone: String!
    $busType: ID!
    $travelerNumber: Int!
    $fromCity: ID!
    $toCity: ID!
    $fromDate: String!
    $toDate: String
  ) {
    requestPrivateBus(
      name: $name
      phone: $phone
      bus_type_id: $busType
      traveler_number: $travelerNumber
      from_city_id: $fromCity
      to_city_id: $toCity
      from_date: $fromDate
      to_date: $toDate
    ) {
      status
      message
      message_ar
    }
  }
`;
