import React from 'react';
import styled from '@emotion/styled/macro';

import {  MainHeader } from './components';

const HeaderWrapper = styled.header`
  z-index: 3;
  position: relative;
  @media screen and (max-width: 1025px) {
    z-index: 100;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      {/* <SubHeader /> */}
      <MainHeader color={'#FFF'} />
    </HeaderWrapper>
  );
};

export default Header;
