import gql from 'graphql-tag';

const CUSTOMER_CODE = gql`
  query getCustomerCode($phone: String!) {
    getCustomerCode(phone: $phone) {
      status
      message
      data {
        expiry_date
      }
    }
  }
`;

export default CUSTOMER_CODE;
