/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const BusIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        height: 15px;
        width: 20px;
        vertical-align: middle;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.711697 9.22797V5.8156V2.09919C0.711697 0.941708 1.64586 0 2.79406 0H16.3072C18.0909 0 19.5421 1.46292 19.5421 3.26104V9.22793C19.8199 9.43832 20 9.77323 20 10.1498V10.1944C20 10.8294 19.4875 11.346 18.8576 11.346H17.389C17.2166 12.3765 16.3257 13.1642 15.2552 13.1642C14.1846 13.1642 13.2937 12.3765 13.1214 11.346H7.13234C6.96 12.3765 6.06908 13.1642 4.99856 13.1642C3.92803 13.1642 3.03712 12.3765 2.86477 11.346H1.39612C0.766193 11.346 0.253731 10.8294 0.253731 10.1944V10.1498C0.253778 9.77323 0.433868 9.43832 0.711697 9.22797ZM1.4332 5.45193H2.42326C2.47391 5.45193 2.51513 5.41042 2.51513 5.35931V2.55548C2.51513 2.50442 2.47396 2.46286 2.42326 2.46286H1.4332V5.45193ZM19.2785 10.1498V10.1944C19.2785 10.4283 19.0897 10.6186 18.8576 10.6186H17.389C17.3341 10.2901 17.2061 9.98649 17.0225 9.72499H18.8576C19.0897 9.72499 19.2785 9.91555 19.2785 10.1498ZM15.2552 12.4368C16.0508 12.4368 16.6981 11.7843 16.6981 10.9822C16.6981 10.1802 16.0508 9.52764 15.2552 9.52764C14.4595 9.52764 13.8123 10.1802 13.8123 10.9822C13.8123 11.7843 14.4595 12.4368 15.2552 12.4368ZM13.1214 10.6186H7.13239C6.9601 9.5881 6.06913 8.80035 4.99861 8.8004C4.6784 8.8004 4.3745 8.87143 4.10081 8.99775H1.4332V6.17931H2.42331C2.87184 6.17931 3.23668 5.81148 3.23668 5.35936V2.55552C3.23668 2.10336 2.87179 1.73557 2.42331 1.73557H1.48212C1.64066 1.15511 2.16861 0.727386 2.79406 0.727386H16.3072C17.6931 0.727386 18.8206 1.86397 18.8206 3.26109V8.99765H16.153C15.8792 8.87129 15.5754 8.8003 15.2552 8.8003C14.1846 8.8003 13.2937 9.58805 13.1214 10.6186ZM4.99861 12.4368C5.79424 12.4368 6.44153 11.7843 6.44153 10.9822C6.44153 10.1802 5.79424 9.52764 4.99861 9.52764C4.20298 9.52764 3.55569 10.1802 3.55569 10.9822C3.55569 11.7843 4.20298 12.4368 4.99861 12.4368ZM1.39617 10.6186C1.16408 10.6186 0.975286 10.4283 0.975286 10.1944V10.1498C0.975286 9.91555 1.16408 9.72499 1.39617 9.72499H3.23125C3.04765 9.98649 2.9197 10.2901 2.86477 10.6186H1.39617Z"
        fill="#009FE3"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95521 5.7146V2.74813C4.95521 2.26979 5.30677 1.88062 5.73898 1.88062H16.4423C17.367 1.88062 18.1194 2.71334 18.1194 3.73692V5.7146C18.1194 6.19293 17.7678 6.58211 17.3356 6.58211H5.73893C5.30677 6.58211 4.95521 6.19293 4.95521 5.7146ZM11.1896 2.65014H8.45683V5.81258H11.1896V2.65014ZM14.6177 5.81258H11.8849V2.65014H14.6177V5.81258ZM17.4241 5.7146V3.73692C17.4241 3.13766 16.9837 2.65014 16.4423 2.65014H15.3129V5.81258H17.3356C17.3845 5.81258 17.4241 5.76862 17.4241 5.7146ZM5.73898 5.81258C5.69013 5.81258 5.65045 5.76862 5.65045 5.7146V2.74813C5.65045 2.69405 5.69017 2.65014 5.73898 2.65014H7.76163V5.81258H5.73898Z"
        fill="#009FE3"
        stroke={color}
      />
      <path
        d="M2.60444 8.46264C2.34479 8.46264 2.13429 8.31691 2.13429 8.13715V7.84782C2.13429 7.66807 2.34479 7.52234 2.60444 7.52234C2.86408 7.52234 3.07458 7.66807 3.07458 7.84782V8.13715C3.07458 8.31691 2.86408 8.46264 2.60444 8.46264Z"
        fill="#009FE3"
        stroke={color}
      />
    </svg>
  );
};

export default BusIcon;

BusIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
