/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const InstagramLogo = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        fill: ${color || '#FFF'};
        max-width: 100%;
        height: 22px;
        width: 22px;
      `}
    >
      <g>
        <path d="M15.37 0H5.858A5.865 5.865 0 0 0 0 5.858v9.512a5.865 5.865 0 0 0 5.858 5.858h9.512a5.865 5.865 0 0 0 5.858-5.858V5.858A5.865 5.865 0 0 0 15.37 0zm3.975 15.37a3.98 3.98 0 0 1-3.975 3.975H5.858a3.98 3.98 0 0 1-3.975-3.975V5.858a3.98 3.98 0 0 1 3.975-3.975h9.512a3.98 3.98 0 0 1 3.975 3.975v9.512z" />
        <path d="M10.476 5.238a5.244 5.244 0 0 0-5.238 5.238 5.244 5.244 0 0 0 5.238 5.238 5.244 5.244 0 0 0 5.238-5.238 5.244 5.244 0 0 0-5.238-5.238zm0 8.672a3.438 3.438 0 0 1-3.434-3.434 3.438 3.438 0 0 1 3.434-3.434 3.438 3.438 0 0 1 3.435 3.434 3.438 3.438 0 0 1-3.435 3.434zm6.258-10.12c-.413 0-.818.168-1.11.46a1.579 1.579 0 0 0-.461 1.112c0 .413.168.819.461 1.112.292.291.697.46 1.11.46.415 0 .819-.169 1.112-.46a1.576 1.576 0 0 0 0-2.223 1.578 1.578 0 0 0-1.112-.46z" />
      </g>
    </svg>
  );
};

export default InstagramLogo;

InstagramLogo.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
