// A function to construct the URL that's being sent from the my trips view to the track trip view

import i18next from 'i18next';

export const buildTrackingURLParams = ticketGroup => {
  const searchParams = new URLSearchParams();
  const trackingLocation = ticketGroup.tripDetails.tracking_location;
  const { language } = i18next;

  searchParams.append('from_long', ticketGroup.fromLongitude);
  searchParams.append('from_lat', ticketGroup.fromLatitude);
  searchParams.append('to_long', ticketGroup.toLongitude);
  searchParams.append('to_lat', ticketGroup.toLatitude);
  searchParams.append('long', trackingLocation && trackingLocation.longitude);
  searchParams.append('lat', trackingLocation && trackingLocation.latitude);
  searchParams.append(
    'from_station',
    ticketGroup[language === 'en' ? 'fromLocationName' : 'toLocationNameAr']
  );
  searchParams.append(
    'to_station',
    ticketGroup[language === 'en' ? 'toLocationName' : 'fromLocationNameAr']
  );
  searchParams.append(
    'from_city',
    ticketGroup[language === 'en' ? 'fromCityName' : 'fromCityNameAr']
  );
  searchParams.append(
    'to_city',
    ticketGroup[language === 'en' ? 'toCityName' : 'toCityNameAr']
  );
  searchParams.append('from_time', ticketGroup.fromLocationTime);
  searchParams.append('to_time', ticketGroup.toLocationTime);

  return searchParams;
};
