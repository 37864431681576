/* This component is the register form that the user uses to create a new account. */

import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form, Input, Spin, Icon } from 'antd';
import {colors, notify} from 'utilities';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { REGISTER,GUEST_REGISTER } from 'services';
import { Button } from 'components/button';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useAuthDataContext } from 'utilities';
import { useLocation } from 'react-router-dom'

const FormWrapper = styled.div`
  background-color: white;
  padding: 30px 40px;
  border-radius: 20px;
  .ant-form-item-label > label::after {
    content: none;
  }
  .ant-form-item-required::before {
    content: none;
  }
  .ant-input {
    border-radius: 0;
  }
  .ant-form-item-label {
    font-family: ${props => props.font};
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: 1025px) {
      text-align: start;
    }
  }
  .ant-form-item-label > label {
    color: ${colors.primaryColor};
  }
  @media screen and (max-width: 1025px) {
    .ant-form-item-control {
      text-align: center;
    }
  }
`;

const FormTitle = styled.div`
display : flex;
justify-content: space-between;
align-items: center;
h1{
font-family: ${props => props.font};
color: ${colors.primaryColor};
font-weight: 500;
@media screen and (max-width: 1025px) {
  text-align: center;
}
}
`;

const InlineFormItems = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1025px) {
    display: block;
  }
  .ant-form-item {
    width: 45%;
    @media screen and (max-width: 1025px) {
      width: 100%;
    }
  }
`;

const EyeIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  // left: 5%;
  right: 5%;
  line-height: 1;
  transform: translateY(-50%);
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${isMobile ? 'column' : 'row'};
  gap: 10px;
`

/**
 * The register form component.
 */
const RegisterForm = ({
  form: { getFieldDecorator, validateFields },
  setRegistrationStep,
  setCustomerData,
  setStep,
  guest,
  setGuest,
}) => {
  const {onLogin,cartToken} = useAuthDataContext();
  const { language } = i18next;
  const { t } = useTranslation('register');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [register, { loading, error }] = useMutation(REGISTER);
  const [
    guestRegister,
    { loading: guestLoading, error: guestError },
  ] = useMutation(GUEST_REGISTER);
  const registerErrors = {};
  const location = useLocation();

  if (error||guestError) {
    let error_=error||guestError;
    console.log(error_['graphQLErrors'][0])
    const {
      extensions: { validation },
      message,
    } = error_['graphQLErrors'][0];
    try {
      const { name, phone, email, password } = validation;
      registerErrors['name'] = name && name.length && name.join(', ');
      registerErrors['phone'] = phone && phone.length && phone.join(', ');
      registerErrors['email'] = email && email.length && email.join(', ');
      registerErrors['password'] =
          password && password.length && password.join(', ');
    }catch (error){
      if (message.search('number')>1||message.search('الرقم')>1)
        registerErrors['phone_error'] = message;
      else if (message){
        notify('error',message)
      }
      else{
        notify('error','phone or email is exist')

      }
    }

  }
  const handleSubmit = (e, register) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        if (guest){
          delete  values.password;
          guestRegister({
            variables:values
          }).then(res=>{
            const {
              data: {
                guestRegistration: { status, message, message_ar, token, data: customer },
              },
            } = res;
            setCustomerData(customer);
            if (setStep) {
              setStep('verification');
            } else {
              setRegistrationStep(2);
            }
          })
        }else{
          register({
            variables: values,
          }).then(res => {
            const {
              data: {
                customerFullRegistration: { data: customerData },
              },
            } = res;
            firebase.analytics().logEvent('create_account', {
              source: `${window.location.href}`,
              email: customerData.email,
              phoneNumber: customerData.phone,
            });
            setCustomerData(customerData);
            if (setStep) {
              setStep('verification');
            } else {
              setRegistrationStep(2);
            }
          });
        }
      }
    });
  };

  const font = language === 'en' ? 'FreightSans Pro' : 'GE SS Two';

  return (
    <Spin spinning={loading  ||  guestLoading}>
=======
      <FormWrapper font={font}>
        <FormTitle font={font}>
          <h1>{t(guest ? 'guestheader' : 'header')}</h1>
          {guest && (
          <Icon
            type={language === 'ar' ? 'arrow-left' : 'arrow-right'}
            style={{ fontSize: 20, color: colors.primaryColor }}
            onClick={() => setGuest(false)}
          />
          )}
        </FormTitle>
        <Form onSubmit={e => handleSubmit(e, register)}>
          <InlineFormItems>
            <Form.Item
              label={t('fields.name')}
              {...(registerErrors.name && {
                help: registerErrors.name,
                validateStatus: 'error',
              })}
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: t('validations.nameValidation'),
                    pattern: new RegExp(
                      /^[^0-9][\u0621-\u064Aa-zA-Z\-_\s\d]+$/
                    ),
                  },
                ],
              })(<Input type="text" />)}
            </Form.Item>
            <Form.Item
              label={t('fields.phone')}
              {...(registerErrors.phone && {
                help: registerErrors.phone && t('errors.phoneError'),
                validateStatus: 'error',
              })}
              {...(registerErrors.phone_error && {
                help: registerErrors.phone_error,
                validateStatus: 'error',
              })}
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: t('validations.phoneValidation'),
                    pattern: new RegExp(/^\+?[\d]{7,16}$/),
                  },
                ],
              })(<Input className="forced_ltr forced_english" />)}
            </Form.Item>
          </InlineFormItems>
          <InlineFormItems>
            <Form.Item
              label={t('fields.email')}
              {...(registerErrors.email && {
                help: registerErrors.email && t('errors.emailError'),
                validateStatus: 'error',
              })}
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    required: true,
                    message: t('validations.emailValidation'),
                  },
                ],
              })(<Input className="forced_ltr forced_english" />)}
            </Form.Item>
            <Form.Item
              hidden={guest}
              label={t('fields.password')}
              {...(registerErrors.password && {
                help: registerErrors.password,
                validateStatus: 'error',
              })}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    message: t('validations.passwordValidation'),
                    min: 6,
                  },
                ],
              })(
                <Input
                  className="forced_ltr forced_english"
                  type={passwordHidden ? 'password' : 'text'}
                />
              )}
              <EyeIconWrapper>
                <Icon
                  type={passwordHidden ? 'eye' : 'eye-invisible'}
                  onClick={() => setPasswordHidden(!passwordHidden)}
                />
              </EyeIconWrapper>
            </Form.Item>
          </InlineFormItems>
          <Form.Item>
            <ButtonContainer>
              <Button primary htmlType="submit">
                {t(guest === true ? 'buttons.guest' : 'buttons.submitButton')}
              </Button>
              {!guest && location.pathname === '/ticketing' && (
                <Button
                  primary
                  htmlType="none"
                  onClick={() => {
                    setGuest(true);
                  }}
                >
                  {t('buttons.guest')}
                </Button>
              )}
            </ButtonContainer>
          </Form.Item>
          <p>
            {t('haveAccount')}{' '}
            {setStep ? (
              <>
                <Button
                  clean
                  small
                  onClick={() => {
                    setStep('login');
                    setGuest(false);
                  }}
                >
                  {t('buttons.loginButton')}
                </Button>
              </>
            ) : (
              <Link to="/login">{t('buttons.loginButton')}</Link>
            )}
          </p>
        </Form>
      </FormWrapper>
    </Spin>
  );
};

export default Form.create({
  // validateMessages: {
  //   string: {
  //     len: '%s يجب أن تطابق %s أحرف',
  //   },
  // },
})(RegisterForm);
