import  {
  useEffect,
  useState,
  useRef,
} from 'react';

export const useCounter = (initialValue, step, delay, stoppingValue, autoStart = false,) => {
  const callbackRef = useRef(() => null);
  const [counter, setCounter] = useState(initialValue);
  const [intervalId, setIntervalId] = useState(null);

  const killInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const resetCounter = () => {
    setCounter(initialValue);
  };

  const startCounter = () => {
    killInterval();
    resetCounter();
    setIntervalId(setInterval(() => callbackRef.current(), delay));
  };
  const stopCounter = (completed = false) => {
    killInterval();
    resetCounter();
  };
  const endReached =  (step > 0 && counter >= stoppingValue) || (step < 0 && counter <= stoppingValue);
  
  useEffect(() => {
    if (autoStart) {
      startCounter();
    }
  }, [autoStart])

  useEffect(() => () => killInterval(), [intervalId]);

  useEffect(() => {
    callbackRef.current = () => {
      if (endReached) {
        stopCounter(true);
        return;
      }
      setCounter(counter + step);
    };
  }, [counter,endReached,step]);

  return {
    counter,
    startCounter,
    stopCounter,
  }
}