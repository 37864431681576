import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  common_ar,
  common_en,
  cancelTrip_ar,
  cancelTrip_en,
  cart_ar,
  cart_en,
  complaint_ar,
  complaint_en,
  contactUs_ar,
  contactUs_en,
  filterTrip_ar,
  filterTrip_en,
  privateBus_ar,
  privateBus_en,
  ticket_ar,
  ticket_en,
  login_ar,
  login_en,
  css_ar,
  css_en,
  destinations_ar,
  destinations_en,
  faqs_ar,
  faqs_en,
  footer_ar,
  footer_en,
  header_ar,
  header_en,
  home_ar,
  home_en,
  traveLlines_ar,
  traveLlines_en,
  stations_ar,
  stations_en,
  profile_ar,
  profile_en,
  myTrips_ar,
  myTrips_en,
  about_ar,
  about_en,
  busClasses_ar,
  busClasses_en,
  forgotPassword_ar,
  forgotPassword_en,
  register_ar,
  register_en,
  verification_ar,
  verification_en,
  noResults_ar,
  noResults_en,
  notifications_ar,
  notifications_en,
  ourServices_ar,
  ourServices_en,
  paymentOptions_ar,
  paymentOptions_en,
  busSalon_ar,
  busSalon_en,
  searchResult_ar,
  searchResult_en,
  tabPanel_ar,
  tabPanel_en,
  summary_ar,
  summary_en,
  trackTrip_ar,
  trackTrip_en,
  termsConditions_ar,
  termsConditions_en,
  mobileApp_en,
  mobileApp_ar,
  deleteAccount_ar,
  deleteAccount_en,
  dahab_ar,
  dahab_en,
  hurghada_ar,
  hurghada_en,
  sharmelsheikh_ar,
  sharmelsheikh_en,
  northcoast_ar,
  northcoast_en,
  asyout_ar,
  asyout_en,
  rasGhareb_ar,
  rasGhareb_en,
  luxor_ar,
  luxor_en,
  asyoutBlog_ar,
  asyoutBlog_en,
  luxorBlog_en,
  luxorBlog_ar,
  haurgadaBlog_ar,
  haurgadaBlog_en,
  blogs_ar,
  blogs_en,
  secret_key_ar,
  secret_key_en,
  luxor_hurghada_en,
  luxor_hurghada_ar,
  qena_hurghada_ar,
  qena_hurghada_en,
  egypt_beauty_ar,
  egypt_beauty_en
} from 'translations';

// To enforce the app to use the Arabic language on the first visit.
if (!localStorage.getItem('i18nextLng'))
  localStorage.setItem('i18nextLng', 'en');

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'common',
      'cancelTrip',
      'cart',
      'complaint',
      'contactUs',
      'filterTrip',
      'footer',
      'header',
      'privateBus',
      'ticket',
      'login',
      'home',
      'faqs',
      'destinations',
      'stations',
      'profile',
      'myTrips',
      'about',
      'busClasses',
      'forgotPassword',
      'register',
      'verification',
      'noResults',
      'notifications',
      'ourServices',
      'paymentOptions',
      'busSalon',
      'searchResult',
      'tabPanel',
      'summary',
      'trackTrip',
      'termsConditions',
      'css',
      'deleteAccount',
      'dahab',
      'hurghada',
      'sharmelsheikh',
      'northcoast',
      'travelLines',
      'asyout',
      'rasGhareb',
      'luxor',
      'assyoutBlog',
      'luxorBlog',
      'hurghadaBlog',
      'blogs',
      'secretKey',
      'luxor_hurghada',
      'qena_hurghada',
      'egypt_beauty',
    ],
    defaultNS: 'home',
    fallbackLng: ['ar', 'en'],
    load: 'languageOnly',
    resources: {
      en: {
        common: common_en,
        cancelTrip: cancelTrip_en,
        cart: cart_en,
        complaint: complaint_en,
        contactUs: contactUs_en,
        filterTrip: filterTrip_en,
        footer: footer_en,
        header: header_en,
        privateBus: privateBus_en,
        ticket: ticket_en,
        login: login_en,
        css: css_en,
        home: home_en,
        faqs: faqs_en,
        destinations: destinations_en,
        stations: stations_en,
        profile: profile_en,
        myTrips: myTrips_en,
        about: about_en,
        busClasses: busClasses_en,
        forgotPassword: forgotPassword_en,
        register: register_en,
        verification: verification_en,
        noResults: noResults_en,
        notifications: notifications_en,
        ourServices: ourServices_en,
        paymentOptions: paymentOptions_en,
        busSalon: busSalon_en,
        searchResult: searchResult_en,
        tabPanel: tabPanel_en,
        summary: summary_en,
        trackTrip: trackTrip_en,
        termsConditions: termsConditions_en,
        mobileApp: mobileApp_en,
        deleteAccount: deleteAccount_en,
        dahab: dahab_en,
        hurghada: hurghada_en,
        sharmelsheikh: sharmelsheikh_en,
        northcoast: northcoast_en,
        travelLines: traveLlines_en,
        asyout: asyout_en,
        rasGhareb: rasGhareb_en,
        luxor: luxor_en,
        assyoutBlog: asyoutBlog_en,
        luxorBlog: luxorBlog_en,
        hurghadaBlog: haurgadaBlog_en,
        blogs:blogs_en,
        secretKey : secret_key_en,
        luxor_hurghada: luxor_hurghada_en,
        qena_hurghada: qena_hurghada_en,
        egypt_beauty : egypt_beauty_en
      },
      ar: {
        common: common_ar,
        cancelTrip: cancelTrip_ar,
        cart: cart_ar,
        complaint: complaint_ar,
        contactUs: contactUs_ar,
        filterTrip: filterTrip_ar,
        footer: footer_ar,
        header: header_ar,
        privateBus: privateBus_ar,
        ticket: ticket_ar,
        login: login_ar,
        css: css_ar,
        home: home_ar,
        faqs: faqs_ar,
        destinations: destinations_ar,
        stations: stations_ar,
        profile: profile_ar,
        myTrips: myTrips_ar,
        about: about_ar,
        busClasses: busClasses_ar,
        forgotPassword: forgotPassword_ar,
        register: register_ar,
        verification: verification_ar,
        noResults: noResults_ar,
        notifications: notifications_ar,
        ourServices: ourServices_ar,
        paymentOptions: paymentOptions_ar,
        busSalon: busSalon_ar,
        searchResult: searchResult_ar,
        tabPanel: tabPanel_ar,
        summary: summary_ar,
        trackTrip: trackTrip_ar,
        termsConditions: termsConditions_ar,
        mobileApp: mobileApp_ar,
        deleteAccount: deleteAccount_ar,
        dahab: dahab_ar,
        hurghada: hurghada_ar,
        sharmelsheikh: sharmelsheikh_ar,
        northcoast: northcoast_ar,
        travelLines: traveLlines_ar,
        asyout: asyout_ar,
        rasGhareb: rasGhareb_ar,
        luxor: luxor_ar,
        assyoutBlog:asyoutBlog_ar,
        luxorBlog: luxorBlog_ar,
        hurghadaBlog: haurgadaBlog_ar,
        blogs:blogs_ar,
        secretKey : secret_key_ar,
        luxor_hurghada: luxor_hurghada_ar,
        qena_hurghada: qena_hurghada_ar,
        egypt_beauty : egypt_beauty_ar

      },
    },
  });

export default i18next;
