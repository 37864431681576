/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const Timer = ({ color, width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock"
         viewBox="0 0 16 16"  css={css`
        fill: ${color};
        width: ${width || '35px'};
        height: ${height || '35px'};
      `}
    >

      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />

    </svg>

  );
};

export default Timer;
Timer.propTypes = {
  /** color for svg */
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
