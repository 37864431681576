// A function to flaten the ticket structure from nested objects to a 1 level object
// This is to simplify working with tickets throughout the app

export const restructureTickets = tripTickets =>
  tripTickets.reduce((tickets, ticket) => {
    const {
      id: ticketId,
      uuid: ticketUUID,
      code: ticketCode,
      status: ticketStatus,
      status_ar: ticketStatusAr,
      from_date: fromDate,
      created_at: createdAt,
      remaining_cart_time: remainingTime,
      from_location: {
        long: fromLongitude,
        lat: fromLatitude,
        id: fromLocationId,
        name_en: fromLocationName,
        name_ar: fromLocationNameAr,
        city: { name_ar: fromCityNameAr, name_en: fromCityName },
      },
      to_location: {
        long: toLongitude,
        lat: toLatitude,
        id: toLocationId,
        name_en: toLocationName,
        name_ar: toLocationNameAr,
        city: { name_ar: toCityNameAr, name_en: toCityName },
      },
      trip: { id: tripId, ref_code: tripCode, date, time },
      trip: tripDetails,
      seat_number: seatNumber,
      seat_type: {
        id: seatTypeId,
        name_ar: seatTypeName,
        name_en: seatTypeNameEn,
      },
      trip_route_line_id: routeLineId,
      qr_code_name: qrCode,
      kiosk_number: kioskNumber,
      payment_method: paymentMethod,
      price,
      original_price,
      order: { order_number: orderNumber } = { order_number: null },
    } = ticket;

    const groupTicketId = `${routeLineId}_${fromLocationId}_${toLocationId}`;

    tickets[groupTicketId] = tickets[groupTicketId] || {
      fromLongitude,
      fromLatitude,
      toLongitude,
      toLatitude,
      fromLocationId,
      toLocationId,
      fromLocationName,
      toLocationName,
      fromLocationNameAr,
      toLocationNameAr,
      fromCityNameAr,
      toCityNameAr,
      fromCityName,
      toCityName,
      createdAt,
      remainingTime,
      fromLocationTime: tripDetails.locations.find(
        location => fromLocationId === location.id
      ).pivot.time,
      toLocationTime: tripDetails.locations.find(
        location => toLocationId === location.id
      ).pivot.time,
      tripCode,
      tripId,
      seatTypeId,
      date,
      fromDate,
      time,
      seats: [],
      groupPrice: 0,
      originalGroupPrice: 0,
      tripDetails,
      routeLineId,
      paymentMethod,
    };

    tickets[groupTicketId].seats.push({
      ticketCode,
      ticketId,
      ticketUUID,
      seatNumber,
      seatTypeName,
      seatTypeNameEn,
      seatTypeId,
      price,
      original_price,
      qrCode,
      kioskNumber,
      ticketStatus,
      ticketStatusAr,
      orderNumber,
    });

    tickets[groupTicketId].groupPrice += price;
    tickets[groupTicketId].originalGroupPrice =
      original_price && original_price !== price
        ? tickets[groupTicketId].originalGroupPrice + original_price
        : tickets[groupTicketId].originalGroupPrice + price;

    return tickets;
  }, {}) || {};
