import gql from 'graphql-tag';

export default gql`
  query getTicketByUuid($ticketId: String!) {
    getTicketByUuid(uuid: $ticketId) {
      id
      code
      uuid
      status
      status_ar
      from_date
      from_location {
        id
        name_en
        name_ar
        city {
          name_en
          name_ar
        }
      }
      to_location {
        id
        name_en
        name_ar
        city {
          name_en
          name_ar
        }
      }
      routeLine {
        id
      }
      kiosk_number
      qr_code_name
      seat_number
      seat_type {
        id
        name_en
        name_ar
      }
      price
      trip {
        id
        date
        ref_code
        locations {
          id
          name_en
          name_ar
          pivot {
            time
            date
          }
        }
      }
      order {
        id
        order_number
        payment_method
      }
    }
  }
`;
