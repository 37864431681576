/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const BuffetIcon = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      css={css`
        fill: ${color};
        width: ${width || '35px'};
        height: ${height || '35px'};
      `}
    >
      <g>
        <g>
          <path
            d="M491.387,229.367v-30.053h-94.42v30.053h-3.033l-14.991-75.009v-33.692h-27.631
			c-3.683-47.042-41.333-84.692-88.375-88.375v-12.48h-15.163v12.48c-47.043,3.682-84.693,41.333-88.376,88.375h-27.631v40.133
			l-13.703,68.568h-3.033v-30.053H20.613v30.053H0v44.548h10.12v218.274h52.442v-88.598h386.42v88.598h52.898V273.915H512v-44.548
			H491.387z M412.13,214.477h64.094v14.89H412.13V214.477z M378.471,229.367H369.9l-13.326-66.678h8.571L378.471,229.367z
			 M255.356,47.155c42.157,0,76.904,32.338,80.74,73.51H174.615C178.451,79.495,213.198,47.155,255.356,47.155z M146.932,135.829
			h12.17h192.509h12.171v11.698h-6.278h-19.422H173.919h-20.709h-6.277V135.829z M255.356,209.561
			c34.793,0,67.051-14.815,87.251-39.389l11.83,59.194H157.562l11.585-57.968C189.404,195.232,221.151,209.561,255.356,209.561z
			 M181.635,162.689h147.441c-17.436,19.832-44.499,31.709-73.721,31.709C226.133,194.398,199.073,182.521,181.635,162.689z
			 M146.853,162.689h8.572l-13.326,66.678h-8.571L146.853,162.689z M35.776,214.477H99.87v14.89H35.776V214.477z M15.163,244.53
			h5.451h12.404v14.223H15.163V244.53z M47.399,477.026H25.282v-17.14h22.117V477.026z M47.399,444.724H25.282V273.915h7.734
			v129.675h14.382V444.724z M463.821,388.429H48.179v-17.156h415.641V388.429z M463.821,356.109L463.821,356.109h-60.551v-39.63
			h-15.163v39.63h-78.723v-39.63H294.22v39.63h-78.722v-39.63h-15.163v39.63h-78.723v-39.63h-15.163v39.63h-58.27V244.53h66.854
			h0.002h39.496h202.937h39.496h0.002h66.854V356.109z M486.718,477.026h-22.572v-17.14h22.572V477.026z M486.718,444.724h-22.572
			v-41.133h14.838V273.915h7.734V444.724z M496.838,258.754L496.838,258.754h-17.855v-14.223h12.404h5.451V258.754z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M269.145,58.945l-4.564,14.46c4.044,1.277,7.999,2.943,11.754,4.951l7.151-13.371
			C278.907,62.537,274.082,60.504,269.145,58.945z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M293.52,71.296l-8.951,12.238c8.727,6.382,15.941,14.824,20.863,24.415l13.491-6.924
			C312.925,89.345,304.142,79.065,293.52,71.296z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect x="312.203" y="253.937" width="112.266" height="15.163" />
        </g>
      </g>
      <g>
        <g>
          <rect x="432.555" y="253.937" width="15.163" height="15.154" />
        </g>
      </g>
    </svg>
  );
};

export default BuffetIcon;
BuffetIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
