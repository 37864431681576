import gql from 'graphql-tag';

export default gql`
  mutation sendComplaint(
    $customerId: ID!
    $ticketId: ID!
    $reason: String!
    $description: String!
  ) {
    sendComplaint(
      customer_id: $customerId
      ticket_id: $ticketId
      reason: $reason
      description: $description
    ) {
      status
      message
      message_ar
    }
  }
`;
