/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const NavIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      sroke={color}
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        transform: rotate(45deg);
      `}
    >
      <path
        stroke="#fff"
        strokeWidth="2px"
        d="M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z"
      />
    </svg>
  );
};

export default NavIcon;

NavIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
