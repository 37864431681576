/* This is the notifications view that appear in the sub header at the top of the page.
It's dynamic and retreitved from the backed (translations are also from the backend) */

import React from 'react';
import styled from '@emotion/styled/macro';
import { useQuery } from '@apollo/react-hooks';
import { Container } from 'utilities';
import GET_CUSTOMER_NOTIFICATIONS from 'services/notifications/get_customer_notifications';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const NotificationsContainer = styled.div`
  ${Container};
`;

const Notifications = () => {
  const { language } = i18next;
  const {  data } = useQuery(GET_CUSTOMER_NOTIFICATIONS, {
    variables: {
      page: 1,
      first: 10,
    },
  });

  const { t } = useTranslation('notifications');

  return (
    <NotificationsContainer>
      <h1 className="section__title">{t('header')}</h1>
      <ul>
        {data &&
        data.getCustomerNotifications &&
        data.getCustomerNotifications.length ? (
          data.getCustomerNotifications.data.map(notification => (
            <li>
              {notification[language === 'en' ? 'title_en' : 'title_ar']} :{' '}
              {notification[language === 'en' ? 'message_en' : 'message_ar']}
            </li>
          ))
        ) : (
          <li>{t('noNotifications')}</li>
        )}
      </ul>
    </NotificationsContainer>
  );
};

export default Notifications;
