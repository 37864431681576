// This function is used to format time to time with evening and morning

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SunIcon from '../components/svgs/components/sun-icon';
import MoonIcon from '../components/svgs/components/moon-icon';

import { colors } from 'utilities';

export const useFormattedTime = time => {
  const [formattedTime, setFormattedTime] = useState({});
  const { t } = useTranslation('common');

  useEffect(() => {
    const splitTime = time.split(':');
    const formattedTime = {
      time: `${splitTime[0] % 12 || 12}:${splitTime[1]} ${
        splitTime[0] >= 12 ? t('common:time.evening') : t('common:time.morning')
      }`,

      icon:
        splitTime[0] >= 12 ? (
          <MoonIcon color={colors.primaryColor} />
        ) : (
          <SunIcon color={colors.primaryColor} />
        ),
    };

    setFormattedTime(formattedTime);
  }, [t, time]);

  return formattedTime;
};
