import gql from 'graphql-tag';

export default gql`
  query {
    activeLocations {
      id
      name_en
      name_ar
      driver_location
      type
      is_active
      lat
      long
      city {
        id
        name_en
        name_ar
        is_active
      }
    }
  }
`;
