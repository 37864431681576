import gql from 'graphql-tag';

const LOGIN = gql`
  mutation loginCustomer($phone: String!, $password: String!) {
    loginCustomer(phone: $phone, password: $password) {
      status
      responseCode
      message
      message_ar
      token
      code
      data {
        id
        name
        registered
        is_valid
        phone
        email
      }
    }
  }
`;

export default LOGIN;
