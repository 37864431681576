import gql from 'graphql-tag';

const RESET_PASSWORD = gql`
  mutation resetCustomerPassword(
    $phone: String!
    $code: Int!
    $password: String!
  ) {
    response: resetCustomerPassword(
      phone: $phone
      code: $code
      password: $password
    ) {
      status
      responseCode
      message
      message_ar
      token
      data {
        id
        name
        registered
        is_valid
      }
    }
  }
`;

export default RESET_PASSWORD;
