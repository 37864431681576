import gql from 'graphql-tag';

export default gql`
  query getTrip($id: ID!) {
    getTrip(id: $id) {
      id
      ref_code
      access_level
      date
      time
      status
      is_active
    }
  }
`;
