/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const WcIcon = ({ color, width, height }) => {
  return (
    <svg
      viewBox="0 -26 512 512"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        fill: ${color};
        width: ${width || '35px'};
        height: ${height || '35px'};
      `}
    >
      <path d="m382 0h-232c-82.710938 0-150 67.289062-150 150v100c0 5.523438 4.476562 10 10 10s10-4.476562 10-10v-60h16.300781c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-16.300781v-20c0-71.683594 58.316406-130.011719 130-130.011719h172.113281c-35.691406 25.585938-67.113281 78.582031-69.910156 150.011719h-16.203125c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h16v237.039062l-26.1875-24.359374c-3.839844-3.570313-9.785156-3.570313-13.625 0l-35.9375 33.429687-33.179688-33.179687c-3.90625-3.90625-10.234374-3.90625-14.140624 0l-33.183594 33.179687-35.9375-33.429687c-3.835938-3.570313-9.78125-3.570313-13.621094 0l-26.1875 24.359374v-97.039062c0-5.519531-4.476562-10-10-10s-10 4.480469-10 10v120c0 3.980469 2.359375 7.578125 6.007812 9.167969 3.644532 1.589843 7.890626.863281 10.804688-1.847657l36.1875-33.664062 36.1875 33.664062c3.941406 3.667969 10.078125 3.558594 13.882812-.25l32.929688-32.925781 32.929688 32.925781c3.804687 3.808594 9.941406 3.917969 13.882812.253907l36.1875-33.667969 36.1875 33.664062c2.925781 2.71875 7.171875 3.429688 10.804688 1.847657 3.648437-1.589844 6.007812-5.1875 6.007812-9.167969v-90h110c82.234375 0 130-95.332031 130-180 0-85.074219-48.023438-180-130-180zm-110 340v-63.761719c11.246094 24.632813 28.015625 47.921875 50.109375 63.761719zm110 0c-60.652344 0-110-71.773438-110-160 0-88.207031 49.339844-159.980469 109.984375-160h.015625c60.652344 0 110 71.773438 110 160s-49.347656 160-110 160zm0 0" />
      <path d="m382 100c-28.039062 0-50 35.140625-50 80s21.960938 80 50 80 50-35.140625 50-80-21.960938-80-50-80zm0 140c-14.1875 0-30-24.640625-30-60s15.8125-60 30-60 30 24.640625 30 60-15.8125 60-30 60zm0 0" />
      <path d="m196.300781 190c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-40.300781c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10zm0 0" />
      <path d="m116.300781 190c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-40.300781c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10zm0 0" />
      <path d="m20 290c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10 4.476562-10 10-10 10 4.476562 10 10zm0 0" />
    </svg>
  );
};

export default WcIcon;
WcIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
