import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './App';
import client from './apollo_client';
import * as serviceWorker from './serviceWorker';
import './index.css';
import ScrollToTop from './utilities/scrolltotop';
import { Helmet } from 'react-helmet';

// import 'antd-v4/dist/antd.css';
import 'antd/dist/antd.css';

// import i18n (needs to be bundled ;))
import './i18n';
import i18next from 'i18next';

const { language } = i18next;
window.webengage.options('webpush.registerServiceWorker', false);
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      >
        <meta
          name="keywords"
          content="
        blue bus محور المشير
        ,
        buses egypt	
        ,
        hurghada buses	
        ,
        travel bus egypt	
        ,
        bus in egypt	
        ,
        eg bus hurghada	
        ,
        blue bus egypt
        ,
        blue bus booking	
        ,
        cairo to hurghada bus	
        ,
        online bus booking cairo	
        ,
        bus booking egypt	
        ,
        حجز تذاكر اتوبيسات بلو باص
        ,
        بلو باص القاهرة
        ,
        حجز تذاكر بلو باص
        ,
        أسعار blue bus
        ,
        blue buss
        ,
        blue bus reservation
        ,
        موقع بلو باص
        ,
        blue bus prices
        ,
        bule bus
        ,
        blue bus alexandria
        ,
        مواعيد بلو باص من الاسكندرية الى القاهرة
        ,
        حجز blue bus
        ,
        اسعار بلو باص
        ,
        بلو بص
        ,
        سوبر جيت العين السخنة
        ,
        blue bus october
        ,
        رقم بلو باص اكتوبر
        ,
        فئات أتوبيسات جو باص
        ,
        بلو باص شرم الشيخ
        ,
        blue bas
        ,
        بلو باص سوهاج
        ,
        blue bus حجز تذاكر
        ,
        blue bus الغردقة
        ,
        blue bus cairo
        ,
        blue bus dahab
        ,
        blue bus اسعار
        ,
        بلو باص حجز
        ,
        blue bus station
        ,
        bluebus egypt
        ,
        blue bus رمسيس
        ,
        رقم بلو باص
        ,
        بلو باص العين السخنة
        ,
        blubus
        ,
        بلو باص اسكندرية
        ,
        blue bus schedule egypt
        ,
        شركة بلو باص
        ,
        بلو باص اكتوبر
        ,
        ارقام بلو باص
        ,
        blue bus number
        ,
        blue bus schedule
        ,
        blue bus hotline
        ,
        مواعيد بلو باص
        ,
        بلو باص الغردقة
        ,
        Book your next Blue Bus trip easily
        ,
        blue bus مواعيد
        ,
        blue bus اكتوبر
        ,
        blue bus hurghada
        ,
        بلوباص
        ,
        blue bus booking
        ,
        حجز بلو باص
        ,
        blue bus حجز
        ,
        blue bus egypt
        ,
        bluebus
        ,
        بلو باص
        ,
        bus
        ,
        اتوبيس
        "
        />
      </Helmet>{' '}
      <App />
    </BrowserRouter>{' '}
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
