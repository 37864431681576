import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { useAuthDataContext } from 'utilities';
import { isMobile } from 'react-device-detect';

const LinksWrapper = styled.nav`
  grid-column: 2 / 3;
  grid-row: 1 / 3;

  @media screen and (max-width: 820px) {
    order: 1;
    text-align: center;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      flex-basis: 25%;
      a {
        line-height: 45px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
      }

      @media screen and (max-width: 1350px) {
        flex-basis: 33.33%;
      }

      @media screen and (max-width: 920px) {
        a {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 820px) {
        flex-basis: 50%;
        &:nth-of-type(6) {
          margin-bottom: ${isMobile ? '0' : '20px'};
        }
      }
    }
  }
`;

const LinksFooter = () => {
  const { token, customer } = useAuthDataContext();
  const { t } = useTranslation('footer');
  const { phone, email } = customer || {
    id: null,
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <LinksWrapper>
      <ul>
        <li>
          <Link
            to="/"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Book Your Trib"
              })
            }}
          >
            {t('links.bookTrip')}{' '}
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"About Us"
              })
            }}
          >
            {t('links.about')}
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Services"
              })
            }}
          >
            {t('links.service')}
          </Link>
        </li>
        {token && (
          <li>
            <Link
              to="/my-trips"
              onClick={() => {
                firebase.analytics().logEvent('my_trips', {
                  source: `${window.location.href}`,
                  phoneNumber: `${phone}`,
                })
                scrollToTop();
                window.webengage.track('Footer Clicked', {
                  "Footer Name":"My Trips"
                })
              }}
            >
              {t('links.myTrips')}{' '}
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/bus-classes"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Buses"
              })
            }}
          >
            {t('links.busses')}
          </Link>
        </li>
        <li>
          <Link
            to="/destinations"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Destinations"
              })
            }}
          >
            {t('links.destinations')}
          </Link>
        </li>
        <li>
          <Link
            to="/stations"
            onClick={() => {
              scrollToTop();
              firebase.analytics().logEvent('our_stations', {
                source: `${window.location.href}`,
                phoneNumber: `${phone}`,
                email: `${email}`,
              });
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Stations"
              })
            }}
          >
            {t('links.stations')}
          </Link>
        </li>
        <li>
          <Link
            to="/faqs"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"FAQs"
              })
            }}
          >
            {t('links.faqs')}{' '}
          </Link>
        </li>
        <li>
          <Link
            to="/privacy-and-policy"
            onClick={() => {
              scrollToTop();
              window.webengage.track('Footer Clicked', {
                "Footer Name":"Terms And Conditions"
              })
            }}
          >
            {t('links.termsConditions')}
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            onClick={() => {
              scrollToTop();
              firebase.analytics().logEvent('Contact', {
                source: `${window.location.href}`,
                phoneNumber: `${phone}`,
                email: `${email}`,
              });
            }}
          >
            {t('links.contactUs')}
          </Link>
        </li>
        <li>
          <Link
            to="/delete-account"
            onClick={() => {
              scrollToTop();
            }}
          >
            {t('links.deleteAccount')}
          </Link>
        </li>
        <li>
          <Link
            to="/blog"
            onClick={() => {
              scrollToTop();
            }}
          >
            {t('links.blog')}
          </Link>
        </li>
      </ul>
    </LinksWrapper>
  );
};

export default LinksFooter;
/*
faqs
termsConditions
contactUs
 <ul>





        {token && (
          <li>
            <Link
              to="/my-trips"
              onClick={() => {
                firebase.analytics().logEvent('my_trips', {
                  phoneNumber: `${phone}`,
                })
                scrollToTop();
              }}
            >
              {t('links.myTrips')}{' '}
            </Link>
          </li>
        )}



      </ul>
*/