/** @jsx jsx */
import { css, jsx } from '@emotion/core';

/** icon for logout used mainly in menu */
const NoResults = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        height: 30px;
        width: 30px;
        vertical-align: middle;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0106662 11.6108C-0.0553338 13.2021 0.187 14.7281 0.681 16.1358C1.008 17.0674 2.18267 17.3528 2.88067 16.6548L2.89167 16.6438C3.26 16.2754 3.368 15.7294 3.19567 15.2378C2.72633 13.8991 2.549 12.4248 2.74633 10.8891C3.28867 6.6721 6.67233 3.28843 10.8893 2.7461C12.425 2.54876 13.8993 2.72643 15.238 3.19543C15.7297 3.36776 16.2757 3.25976 16.644 2.89143L16.655 2.88043C17.353 2.18243 17.0677 1.00776 16.136 0.680763C14.7283 0.186763 13.2023 -0.0555701 11.6107 0.0107632C5.36767 0.26943 0.269666 5.36743 0.0106662 11.6108ZM23.318 3.28876C23.969 2.63776 23.969 1.58243 23.318 0.931763C22.667 0.281097 21.612 0.281097 20.961 0.931763L0.931666 20.9608C0.281 21.6118 0.281 22.6671 0.931666 23.3178C1.25733 23.6431 1.68367 23.8061 2.11033 23.8061C2.537 23.8061 2.96333 23.6431 3.289 23.3178L23.318 3.28876ZM21.5877 19.7018C23.252 17.6258 24.2497 14.9924 24.2497 12.1248C24.2497 10.6831 23.9973 9.30076 23.535 8.01843C23.2153 7.13243 22.0683 6.89543 21.4023 7.56176L21.345 7.6191C20.9847 7.97943 20.8783 8.51343 21.048 8.99376C21.522 10.3374 21.702 11.8181 21.5033 13.3608C20.961 17.5778 17.5773 20.9611 13.3603 21.5034C11.8177 21.7018 10.3373 21.5218 8.994 21.0478C8.51333 20.8784 7.97933 20.9844 7.61933 21.3448L7.562 21.4021C6.89567 22.0681 7.13267 23.2154 8.01867 23.5348C9.301 23.9971 10.6833 24.2498 12.125 24.2498C14.9927 24.2498 17.626 23.2518 19.7017 21.5874L27.7227 29.6084C27.9833 29.8688 28.3243 29.9988 28.6657 29.9988C29.007 29.9988 29.348 29.8688 29.6087 29.6084C30.1293 29.0874 30.1293 28.2434 29.6087 27.7228L21.5877 19.7018Z"
        fill="#002D59"
      />
    </svg>
  );
};

export default NoResults;
