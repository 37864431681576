import gql from 'graphql-tag';

const UPDATE_CUSTOMER_PHONE = gql`
  mutation updateCustomerPhone(
    $customerId: ID!
    $password: String!
    $phone: String!
  ) {
    updateCustomerPhone(
      customer_id: $customerId
      password: $password
      phone: $phone
    ) {
      status
      message
      message_ar
      responseCode
      data {
        id
      }
    }
  }
`;

export default UPDATE_CUSTOMER_PHONE;
