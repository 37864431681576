import gql from 'graphql-tag';

export default gql`
  query getCustomerCart($customerId: ID!) {
    getCustomerCart(customer_id: $customerId) {
      id
      token
      tickets {
        id
        price
      }
      groupedTickets {
        total
      }
      created_at
      updated_at
    }
  }
`;
