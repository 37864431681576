import gql from 'graphql-tag';

const RESEND = gql`
  mutation resendCode($phone: String!) {
    resendCode(phone: $phone) {
      status
      message
      message_ar
    }
  }
`;

export default RESEND;
