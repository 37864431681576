import gql from 'graphql-tag';

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $customerId: ID!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeCustomerPassword(
      customer_id: $customerId
      current_password: $currentPassword
      new_password: $newPassword
    ) {
      status
      message
      message_ar
      data {
        id
      }
    }
  }
`;

export default CHANGE_PASSWORD;
