/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const PencilIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      sroke={color}
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        width: 15px;
        height: 15px;
        vertical-align: middle;
      `}
    >
      <path
        fill={color}
        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
      />
    </svg>
  );
};

export default PencilIcon;

PencilIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
