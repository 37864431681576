import gql from 'graphql-tag';
import { ticketFragment } from './ticket_fragment';

export default gql`
  mutation removeCartPromoCode($token: String!) {
    removeCartPromoCodeCustomer(token: $token) {
      status
      message
      message_ar
      data {
        id
        token
        created_at
        updated_at
        promo_code {
          code
          active
        }
        tickets {
          ...TicketData
        }
      }
    }
  }
  ${ticketFragment}
`;
