import { css } from '@emotion/core';

const Clearfix = css`
  &::before,
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

export default Clearfix;
